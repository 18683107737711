import api from '@/api/data'

export default {
  state: {
    chart: {},
    table: {},
    option: {}
  },

  mutations: {
    SET_CHART: (state, chart) => {
      state.chart = chart
    },
    SET_TABLE: (state, table) => {
      state.table = table
    },
    SET_OPTION: (state, option) => {
      state.option = option
    }
  },

  actions: {
    GetBeefWebDataOneChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataOneChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataOneTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataOneTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataTwoChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataTwoChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataTwoTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataTwoTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataThreeChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataThreeChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataThreeTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataThreeTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataFourChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataFourChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataFourTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataFourTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataFiveChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataFiveChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataFiveTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataFiveTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataSixChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataSixChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataSixTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataSixTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataSevenChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataSevenChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataSevenTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataSevenTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataEightChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataEightChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataEightTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataEightTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataNineChart ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataNineChart(params).then(response => {
          commit('SET_CHART', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetBeefWebDataNineTable ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefWebDataNineTable(params).then(response => {
          commit('SET_TABLE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetOptionData ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetOptionData(params).then(response => {
          commit('SET_OPTION', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
