<template>
  <b-row class="preheader">
    <b-col class="preheader-left">
      <img class="hand" src="@/assets/image/frontlogo.png" alt="logo" @click="handleLogo">
      <p>Your best explorer in the Chinese beef market</p>
    </b-col>
    <b-col class="preheader-right">
      <div>
        <!--未登录-->
        <div v-if="!isLogin" class="divider">
          <img class="buddha" src="@/assets/image/person.png">
          <span><router-link to="/Login">Login</router-link></span>
          <span>|</span>
          <span><router-link to="/Register">Register</router-link></span>
        </div>
        <!--已登录-->
        <div v-if="isLogin" class="divider">
          <img class="buddha" :src="getAvator($store.getters.userAvator)">
          <span :class="{'red-point' : $store.getters.userRedDot}"><router-link to="/Account"> {{ this.$store.getters.name }} - My BTC Account</router-link></span>
          <span>|</span>
          <span><a href="javascript:void(0)" @click.prevent="logout">Logout</a></span>
        </div>

        <div class="divider hand" @click="handleFollowUs">
          <img src="@/assets/image/flag_china.png">
          <span>中文</span>
        </div>
        <div class="hand" @click="handleContactUs">
          <img src="@/assets/image/phone.png">
          <span>Contact Us</span>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'PreHeader',
  methods: {
    logout () {
      this.$store.dispatch('clearToken')
      this.$router.replace('/BTCInfo')
    },
    handleContactUs () {
      this.$router.push('/ContactUs')
    },
    handleLogo () {
      this.$router.push('/BTCInfo')
    },
    handleFollowUs () {
      sessionStorage.lang = 'zh-cn'
      this.$i18n.locale = sessionStorage.lang
      this.$router.push('/cn/BTCInfo')
    },
    getAvator (path) {
      return path ? process.env.VUE_APP_STATIC + path : require('@/assets/image/person.png')
    }
  }
}
</script>

<style lang="scss" scoped>
$preheader-height: 55px;
.preheader {
  min-width: 1248px;
  margin: 0;
  padding: 0;
  height: $preheader-height;
  background-color: #f5f5f5;
}
.preheader-left {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0;
}
.preheader-left > p {
  margin: 0;
  margin-left: 20px;
  font-size: 14px;
}
.preheader-right {
  display: flex;
  padding: 0 0 0 150px
}
.preheader-right > div {
  flex: 1;
  height: 100%;
  background: #bfe7f9;
  border-radius: $preheader-height 0 0 0;
  display: flex;
  align-items: center;
  padding-left: 35px;
}

.preheader-right > div > div {
  margin-left: 10px;
}

.preheader-right > div > div > img {
  height: 20px;
  width: 20px;
}

.preheader-right > div > div > span {
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
}

.divider {
  border-right: 1px solid #c8ced2;
}

.buddha{
  border-radius: 10px
}

.red-point{
  position: relative;
}

.red-point::before{
  content: " ";
  border: 5px solid red;
  border-radius:5px;
  position: absolute;
  z-index: 1;
  right: -10px;
  top: -5px;
}

</style>
