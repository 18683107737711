<template>
  <b-row class="navbar" align-h="center">
    <ul>
      <li v-for="(item, index) in tabs" :key="index" :class="{ active: current===index }" tabindex="1" @click="handleClickTab(item, index)">
        <a>{{ item.text }}</a>
        <div class="triangle triangle-left"></div>
        <div class="triangle triangle-right"></div>
      </li>
    </ul>
  </b-row>
</template>

<script>
export default {
  name: 'CNNavbar',
  data () {
    return {
      current: 2,
      tabs: [
        { text: '首页', href: '/cn/Homepage' },
        { text: '必孚可视', href: '/cn/BTCEyes' },
        { text: '必孚信息', href: '/cn/BTCInfo' },
        { text: '必孚数据 ', href: '/cn/BTCData' },
        { text: '必孚行情', href: '/cn/BTCPrice' },
        { text: '必孚工厂', href: '/cn/BTCEst' },
        { text: '必孚App', href: '/cn/BTCApp' }
      ]
    }
  },
  mounted () {
    // this.current = parseInt(sessionStorage.getItem('navindex')) || 2
    if (this.handCurrent() === 0) {
      this.current = 0
    } else if (this.handCurrent()) {
      this.current = this.handCurrent()
    } else {
      this.current = 2
    }
  },
  watch: {
    $route (to, from) {
      this.tabs.forEach((item, index) => {
        if (item.href === to.path) {
          this.current = index
        }
      })
    }
  },
  methods: {
    handleClickTab (item, index) {
      this.$router.push(item.href)
      this.current = index
      sessionStorage.setItem('navindex', index)
    },
    handCurrent () {
      let currentNow = 2
      this.tabs.forEach((item, index) => {
        if (item.href === this.$route.path) {
          currentNow = index
        }
      })
      return currentNow
    }
  }
}
</script>

<style lang="scss" scoped>
$tab-color: #ffffff;
$tab-active-color: #006eb9;
// 导航
.navbar {
  min-width: 1248px;
  margin: 0;
  padding: 0;
  background: #f9f9fa;
}
.navbar > ul {
  margin: 0;
  padding: 10px 0 0 0;
  list-style: none;
}
.navbar > ul > li {
  float: left;
  width: 136px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  margin: 0 10px;
  background: $tab-color;
  border-radius: 10px 10px 0 0;
  position: relative;
  cursor: pointer;
  outline: none;
}

.navbar > ul > li > a {
  color: #000000;
  font-weight: bold;
  text-decoration: none;
}

// 三角形
.triangle {
  width: 0;
  height: 0;
  position: absolute;
  border: 5px solid $tab-color;
  border-top-color: transparent!important;
  bottom: 0;
}
.triangle-left {
  border-left-color: transparent!important;
  left: -5px;
}
.triangle-right {
  border-right-color: transparent!important;
  right: -5px;
}

// 点击
.active {
  background-color: $tab-active-color!important;
}
.active > a {
  color: #ffffff!important;
}
.active > .triangle-left, .active > .triangle-right {
  border: 5px solid $tab-active-color;
}
</style>
