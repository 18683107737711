import request from '@/utils/request'
import { btcguid } from '@/utils/auth'

export default {
  GetBtcInfoHeadlines: function (params) {
    return request({
      url: '/GetBtcInfoHeadlines',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000001,
        Data: params
      }
    })
  },

  GetBtcInfoDetail: function (params) {
    return request({
      url: '/GetBtcInfoDetail',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000002,
        Data: params
      }
    })
  },

  GetBtcInfoRecommendation: function (params) {
    return request({
      url: '/GetBtcInfoRecommendation',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000003,
        Data: params
      }
    })
  },

  GetBtcInfoNewsBySection: function (params) {
    return request({
      url: '/GetBtcInfoNewsBySection',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000004,
        Data: params
      }
    })
  },

  GetBeefInfoReport: function (params) {
    return request({
      url: '/GetBeefInfoReport',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000005,
        Data: params
      }
    })
  },

  GetBeefInfoReportDate: function (params) {
    return request({
      url: '/GetBeefInfoReportDate',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000006,
        Data: params
      }
    })
  },

  GetBeefInfoReportByDate: function (params) {
    return request({
      url: '/GetBeefInfoReportByDate',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000007,
        Data: params
      }
    })
  },

  GetWebCollection: function (params) {
    return request({
      url: '/GetWebCollection',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 7000000008,
        Data: params
      }
    })
  },

  getAppNewsDetail: function (params) {
    return request({
      url: '/GetAppNewsDetail',
      method: 'post',
      data: {
        MessageType: 2022,
        MessageID: btcguid(),
        Data: {
          NewsID: params.NewsID,
          IsShare: params.IsShare
        }
      }
    })
  },

  getSimilarArticle: function (params) {
    return request({
      url: '/GetSimilarArticle',
      method: 'post',
      data: {
        MessageType: 2023,
        MessageID: btcguid(),
        Data: {
          NewsID: params.NewsID
        }
      }
    })
  }
}
