import request from '@/utils/request'
import { btcguid } from '@/utils/auth'

export default {
  GetWebPriceDataList: function (params) {
    return request({
      url: '/GetWebPriceDataList',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000001,
        Data: params
      }
    })
  },
  GetWebFrontPriceDictionary: function (params) {
    return request({
      url: '/GetWebFrontPriceDictionary',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000002,
        Data: params
      }
    })
  },
  GetWebPriceVaildDate: function (params) {
    return request({
      url: '/GetWebPriceVaildDate',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000003,
        Data: params
      }
    })
  },
  GetWebPriceDataIndex: function (params) {
    return request({
      url: '/GetWebPriceDataIndex',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000004,
        Data: params
      }
    })
  },
  GetWebPriceLivecattleIndex: function (params) {
    return request({
      url: '/GetWebPriceLivecattleIndex',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000005,
        Data: params
      }
    })
  },
  GetWebFrontMarketReport: function (params) {
    return request({
      url: '/GetWebFrontMarketReport',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000006,
        Data: params
      }
    })
  },
  GetWebNewsAnalysis: function (params) {
    return request({
      url: '/GetWebNewsAnalysis',
      method: 'post',
      data: {
        MessageID: btcguid(),
        MessageType: 3000000007,
        Data: params
      }
    })
  }
}
