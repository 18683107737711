<template>
  <section class="app-main">
    <router-view style="margin-top: 105px;"/>
  </section>
</template>
<script>
export default {
  name: 'AppMain'
}
</script>
<style scoped>
.app-main {
  /* min-height: calc(100vh - 492px); */
  overflow: hidden;
}
</style>
