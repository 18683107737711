<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>

<script>
import { wxShareInit } from '@/utils/wxshare'
import { isWeChat } from '@/utils'

export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  created () {
    window.addEventListener('load', () => {
      if (this.$store.getters.name) {
        this.$store.dispatch('GetUserProfile', this.$store.getters.name)
        this.$store.dispatch('GetFrontUserInfoByUserID', { UserID: this.$store.getters.name })
      }
    })
  },
  updated () {
    if (isWeChat()) {
      wxShareInit(this)
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style>
#app {
  min-width: 1248px!important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

</style>
