export const en = {
  message: {
    _restore: 'Regained Qualification',
    _china_pause: 'Suspended by China',
    _self_pause: 'Voluntarily Suspended',
    _new_approve: 'Newly Approved',
    _cancel: 'Cacelation of Registration',
    _estno_change: 'Registration Number Adjustment',
    _mr: 'Mr',
    _ms: 'Ms',
    // country
    _argentina: 'Argentina',
    _australia: 'Australia',
    _belarus: 'Belarus',
    _brazil: 'Brazil',
    _canada: 'Canada',
    _chile: 'Chile',
    _china: 'China (Mainland)',
    _costarica: 'Costa Rica',
    _france: 'France',
    _hungary: 'Hungary',
    _ireland: 'Ireland',
    _kazakhstan: 'Kazakhstan',
    _denmark: 'Denmark',
    _germany: 'Germany',
    _finland: 'Finland',
    _portugal: 'Portugal',
    _spain: 'Spain',
    _unitedkingdom: 'The United Kingdom',
    _austria: 'Austria',
    _bolivia: 'Bolivia',
    _belgium: 'Belgium',
    _poland: 'Poland',
    _romania: 'Romania',
    _latvia: 'Latvia',
    // '_mexico': 'Mexico',
    _mongolia: 'Mongolia',
    _namibia: 'Namibia',
    _netherlands: 'Netherlands',
    _newzealand: 'New Zealand',
    // '_panama': 'Panama',
    _serbia: 'Serbia',
    _southafrica: 'South Africa',
    _unitedstates: 'United States',
    _uruguay: 'Uruguay',
    _ukraine: 'Ukraine',
    _lithuania: 'Lithuania',
    _italy: 'Italy',
    _switzerland: 'Switzerland',
    _russia: 'Russia',
    // Province
    // Argentina
    _buenosaires: 'Buenos Aires',
    _catamarca: 'Catamarca',
    _chaco: 'Chaco',
    _chubut: 'Chubut',
    _córdoba: 'Córdoba',
    _corrientes: 'Corrientes',
    _entreríos: 'Entre Ríos',
    _formosa: 'Formosa',
    _jujuy: 'Jujuy',
    _lapampa: 'La Pampa',
    _larioja: 'La Rioja',
    _mendoza: 'Mendoza',
    _misiones: 'Misiones',
    _neuquen: 'Neuquén',
    _ríonegrob: 'Río Negro',
    _salta: 'Salta',
    _sanjuan: 'San Juan',
    _sanluis: 'San Luis',
    _santacruz: 'Santa Cruz',
    _santafe: 'Santa Fe',
    _santiagodelestero: 'Santiago del Estero',
    '_tierradelfuego,antártidaeislasdelatlánticosurc': 'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
    _tucumán: 'Tucumán',
    // Australia
    _australiancapitalterritory: 'Australian Capital Territory',
    _newsouthwales: 'New South Wales',
    _northernterritory: 'Northern Territory',
    _queensland: 'Queensland',
    _southaustralia: 'South Australia',
    _tasmania: 'Tasmania',
    _victoria: 'Victoria',
    _westernaustralia: 'Western Australia',
    // Belarus
    '_brestskaya(brest)': 'Brest Region (Brest)',
    '_homyelskaya(homyel)': 'Gomel Region (Gomel)',
    _horadminsk: 'Minsk City',
    '_hrodzyenskaya(hrodna)': 'Grodno Region (Grodno)',
    '_mahilyowskaya(mahilyow)': 'Mogilev Region (Mogilev)',
    _minskaya: 'Minsk Region (Minsk)',
    '_vitsyebskaya(vitsyebsk)': 'Vitebsk Region (Vitebsk)',
    // Brazil
    _acre: 'Acre',
    _alagoas: 'Alagoas',
    _amapa: 'Amapá',
    _amazonas: 'Amazonas',
    _bahia: 'Bahia',
    _ceara: 'Ceará',
    _distritofederal: 'Distrito Federal',
    _espiritosanto: 'Espírito Santo',
    _goias: 'Goiás',
    _maranhao: 'Maranhão',
    _matogrosso: 'Mato Grosso',
    _matogrossodosul: 'Mato Grosso do Sul',
    _minasgerais: 'Minas Gerais',
    _para: 'Pará',
    _paraiba: 'Paraíba',
    _parana: 'Paraná',
    _pernambuco: 'Pernambuco',
    _piaui: 'Piauí',
    _riodejaneiro: 'Rio de Janeiro',
    _riograndedonorte: 'Rio Grande do Norte',
    _riograndedosul: 'Rio Grande do Sul',
    _rondonia: 'Rondônia',
    _roraima: 'Roraima',
    _santacatarina: 'Santa Catarina',
    _saopaulo: 'São Paulo',
    _sergipe: 'Sergipe',
    _tocantins: 'Tocantins',
    // Canada
    _alberta: 'Alberta',
    _britishcolumbia: 'British Columbia',
    _manitoba: 'Manitoba',
    _newbrunswick: 'New Brunswick',
    _newfoundland: 'Newfoundland',
    _northwestterritories: 'Northwest Territories',
    _novascotia: 'Nova Scotia',
    _nunavut: 'Nunavut',
    _ontario: 'Ontario',
    _princeedwardisland: 'Prince Edward Island',
    _quebec: 'Québec',
    _saskatchewan: 'Saskatchewan',
    _yukonterritory: 'Yukon',
    // Chile
    _aisendelgeneralcarlosibanezdelcampo: 'Aysen del General Carlos Ibáñez del Campo',
    _antofagasta: 'Antofagasta',
    _araucania: 'Araucanía',
    _atacama: 'Atacama',
    _aricayparinacota: 'Arica y Parinacota',
    '_bio-bio': 'Bío-Bío',
    _coquimbo: 'Coquimbo',
    _libertadorgeneralbernardoohiggins: "Libertador General Bernardo O'Higgins",
    _loslagos: 'Los Lagos',
    _losrios: 'Los Ríos',
    _magallanesydelaantarticachilena: 'Magallanes y de la Antártica Chilena',
    _maule: 'Maule',
    '_regionmetropolitana(santiago)': 'Metropolitana de Santiago',
    _tarapaca: 'Tarapacá',
    _valparaiso: 'Valparaíso',
    // China
    _anhui: 'Anhui',
    _beijing: 'Beijing',
    _chongqing: 'Chongqing',
    _fujian: 'Fujian',
    _gansu: 'Gansu',
    _guangdong: 'Guangdong',
    _guangxi: 'Guangxi',
    _guizhou: 'Guizhou',
    _hainan: 'Hainan',
    _hebei: 'Hebei',
    _heilongjiang: 'Heilongjiang',
    _henan: 'Henan',
    _hubei: 'Hubei',
    _hunan: 'Hunan',
    _jiangsu: 'Jiangsu',
    _jiangxi: 'Jiangxi',
    _jilin: 'Jilin',
    _liaoning: 'Liaoning',
    _neimongol: 'Inner Mongolia',
    _ningxia: 'Ningxia',
    _qinghai: 'Qinghai',
    _shaanxi: 'Shaanxi',
    _shandong: 'Shandong',
    _shanghai: 'Shanghai',
    _shanxi: 'Shanxi',
    _sichuan: 'Sichuan',
    _tianjin: 'Tianjin',
    _xinjiang: 'Xinjiang',
    '_xizang(tibet)': 'Tibet',
    _yunnan: 'Yunnan',
    _zhejiang: 'Zhejiang',
    // Costarica
    _alajuela: 'Alajuela',
    _cartago: 'Cartago',
    _guanacaste: 'Guanacaste',
    _heredia: 'Heredia',
    _limon: 'Limón',
    _puntarenas: 'Puntarenas',
    _sanjose: 'San José',
    // France
    _alsace: 'Alsace',
    _aquitaine: 'Aquitaine',
    _auvergne: 'Auvergne',
    '_basse-normandie': 'Basse-Normandie',
    _bourgogne: 'Bourgogne',
    _bretagne: 'Bretagne',
    _centre: 'Center',
    '_champagne-ardenne': 'Champagne-Ardenne',
    _corse: 'Crose',
    '_franche-comte': 'Franche Comte',
    '_haute-normandie': 'Haute-Normandie',
    '_ile-de-france': 'Ile-de-France',
    '_languedoc-roussillon': 'Languedoc-Roussillon',
    _limousin: 'Limousin',
    _lorraine: 'Lorraine',
    '_midi-pyrenees': 'Midi-Pyrenees',
    '_nord-pas-de-calais': 'nord-pas-de-calais',
    _paysdelaloire: 'PaysdelaLoire',
    _picardie: 'Picardie',
    '_poitou-charentes': 'Poitou-Charentes',
    '_provence-alpes-cotedazur': 'Provence Alpes Cote DAzur',
    '_rhone-alpes': 'Rhonealpes',
    // Hungary
    _budapest: 'Budapest',
    '_bacs-kiskun': 'Bács-Kiskun',
    _baranya: 'Baranya',
    _bekes: 'Békés',
    '_borsod-abauj-zemplen': 'Borsod-Abauj-Zemplén',
    _csongrad: 'Csongrád',
    _fejer: 'Fejér',
    '_gyr-moson-sopron': 'Gyor-Moson-Sopron',
    '_hajdu-bihar': 'Hajdu-Bihar',
    _heves: 'Heves',
    '_jasz-nagykun-szolnok': 'Jász-Nagykun-Szolnok',
    _komarom: 'Komárom',
    _nograd: 'Nógrád',
    _pest: 'Pest',
    _somogy: 'Somogy',
    '_szabolc-szatmar-bereg': 'Szabolc-Szatmár-Bereg',
    _tolna: 'Tolna',
    _vas: 'Vas',
    _veszprem: 'Veszprém',
    _zala: 'Zala',
    // Ireland
    _carlow: 'Carlow',
    _cavan: 'Cavan',
    _clare: 'Clare',
    _cork: 'Cork',
    _donegal: 'Donegal',
    _dublin: 'Dublin',
    _galway: 'Galway',
    _kerry: 'Kerry',
    _kildare: 'Kildare',
    _kilkenny: 'Kilkenny',
    _laois: 'Laois',
    _leitrim: 'Leitrim',
    _limerick: 'Limerick',
    _longford: 'Longford',
    _louth: 'Louth',
    _mayo: 'Mayo',
    _meath: 'Meath',
    _monaghan: 'Monaghan',
    _offaly: 'Offaly',
    _roscommon: 'Roscommon',
    _sligo: 'Sligo',
    _tipperary: 'Tipperary',
    _waterford: 'Waterford',
    _westmeath: 'Westmeath',
    _wexford: 'Wexford',
    _wicklow: 'Wicklow',
    // Kazakhstan
    _almaty: 'Almaty',
    _aqmola: 'Aqmola',
    _aqtobe: 'Aqtobe',
    _astana: 'Astana',
    _atyrau: 'Atyrau',
    _batysqazaqstan: 'Batys Qazaqstan',
    _bayqongyr: 'Baikonur',
    _mangghystau: 'Mangystau',
    _ongtustikqazaqstan: 'Ongtustik Qazaqstan',
    _pavlodar: 'Pavlodar',
    _qaraghandy: 'Qaraghandy',
    _qostanay: 'Kostanay',
    _qyzylorda: 'Kyzylorda',
    _shyghysqazaqstan: 'Shygys Qazaqstan',
    _soltustikqazaqstan: 'Soltustik Qazaqstan',
    _zhambyl: 'Zhambyl',
    // Mexico
    _aguascalientes: 'Aguascalientes',
    _bajacalifornia: 'Baja California',
    _bajacaliforniasur: 'Baja California Sur',
    _campeche: 'Campeche',
    _chiapas: 'Chiapas',
    _chihuahua: 'Chihuahua',
    _coahuiladezaragoza: 'Coahuila',
    _colima: 'Colima',
    _distritourango: 'Durango',
    _guanajuato: 'Guanajuato',
    _guerrero: 'Guerrero',
    _hidalgo: 'Hidalgo',
    _jalisco: 'Jalisco',
    _mexico: 'Mexico',
    _michoacandeocampo: 'Michoacán',
    _morelos: 'Morelos',
    _nayarit: 'Nayarit',
    _nuevoleon: 'Nuevo León',
    _oaxaca: 'Oaxaca',
    _puebla: 'Puebla',
    _queretarodearteaga: 'Querétaro',
    _quintanaroo: 'Quintana Roo',
    _sansi: 'San Luis Potosí',
    _sinaloa: 'Sinaloa',
    _sonora: 'Sonora',
    _tabasco: 'Tabasco',
    _tamaulipas: 'Tamaulipas',
    _tlaxcala: 'Tlaxcala',
    '_veracruz-llave': 'Veracruz',
    _yucatan: 'Yucatán',
    _zacatecas: 'Zacatecas',
    // Mongolia
    _arhangay: 'Arhangay',
    '_bayan-olgiy': 'BayanUlgii',
    _bayanhongor: 'Bayan Province Hong gold',
    _bulgan: 'Bulgan',
    _darhan: 'Darkhan-Uul',
    _dornod: 'Dornod',
    _dornogovi: 'Dornogovi',
    _dundgovi: 'Dundgovi',
    _dzavhan: 'Zavkhan',
    _erdenet: 'Central',
    '_govi-tiy': 'Govi-Altai',
    _govisumber: 'Govisümber',
    _hovd: 'Khovd',
    _hovsgol: 'Hövsgöl',
    _omnogovi: 'Ömnögovi',
    _orkhon: 'Orkhon',
    _ovorhangay: 'Ovoerhangay',
    _selenge: 'Selenge',
    _suhbaatar: 'Sükhbaatar',
    _tov: 'Hentiy',
    _ulaanbaatar: 'Ulaanbaatar',
    _uvs: 'Uvs',
    // Namibia
    _caprivi: 'Caprivil',
    _erongo: 'Erongo',
    _hardap: 'Hardap',
    _karas: 'Karas',
    _khomas: 'Khomas',
    _kunene: 'Kunene',
    _ohangwena: 'Ohangwena',
    _okavango: 'Kavango',
    _omaheke: 'Omaheke',
    _omusati: 'Omusati',
    _oshana: 'Oshana',
    _oshikoto: 'Oshikoto',
    _otjozondjupa: 'Otjozondjupa',
    // Netherlands
    _drenthe: 'Drenthe',
    _flevoland: 'Flevoland',
    _friesland: 'Friesland',
    _gelderland: 'Gelderland',
    _groningen: 'Groningen',
    _limburg: 'Limburg',
    '_noord-brabant': 'North Brabant',
    '_noord-holland': 'North Holland',
    _overijssel: 'Overijssel',
    _utrecht: 'Utrecht',
    _zeeland: 'Zeeland',
    '_zuid-holland': 'South Holland',
    // Newzealand
    _wellington: 'Wellington',
    _northland: 'Northland',
    _auckland: 'Auckland',
    _waikato: 'Waikato',
    _bayofplenty: 'Bay of Plenty',
    _gisborne: 'Gisborne',
    "_hawke'sbay": "Hawke's Bay",
    _taranaki: 'Taranaki',
    '_manawatu-wanganui': 'Manawatu-Wanganui',
    _tasman: 'Tasman',
    _nelson: 'Nelson',
    _marlborough: 'Marlborough',
    _westcoast: 'West Coast',
    _canterbury: 'Canterbury',
    _otago: 'Otago',
    _southland: 'Southland',
    _chathamIslands: 'ChathamIslands',
    // Panama
    _bocasdeltoro: 'Bocas del Toro',
    _chiriqui: 'Chiriquí',
    _cocle: 'Coclé',
    _colon: 'Colón',
    _darien: 'Darién',
    _herrera: 'Herrera',
    _lossantos: 'Los Santos',
    _panama: 'Panama',
    _sanblas: 'San Blas',
    _veraguas: 'Veraguas',
    // Serbia
    _north_Backa: 'North Bačka',
    _north_banat: 'North Banat',
    _west_Backa: 'West Bačka',
    _central_banat: 'Central Banat',
    _srem: 'Srem',
    _south_banat: 'South Banat',
    _macva: 'Mačva',
    _city_of_belgrade: 'City of Belgrade',
    _podunavlje: 'Podunavlje',
    _branicevo: 'Braničevo',
    _kolubara: 'Kolubara',
    _sumadija: 'Šumadija',
    _bor: 'Bor',
    _moravica: 'Moravica',
    _zlatibor: 'Zlatibor',
    _raska: 'Raška',
    _pomoravlje: 'Pomoravlje',
    _zajecar: 'Zaječar',
    _rasina: 'Rasina',
    _nisava: 'Nišava',
    _toplica: 'Toplica',
    _pirot: 'Pirot',
    _kosovska_mitrovica: 'Kosovska Mitrovica',
    _jablanica: 'Jablanica',
    _pec: 'Peć',
    _kosovo: 'Kosovo',
    _kosovo_pomoravlje: 'Kosovo Pomoravlje',
    _pcinja: 'Pčinja',
    _prizren: 'Prizren',
    _south_Backa: 'South Bačka',
    // Southafrica
    _easterncape: 'Eastern Cape',
    _freestate: 'Free State',
    _gauteng: 'Gauteng',
    '_kwazulu-natal': 'KwaZulu-Natal',
    _mpumalanga: 'Mpumalanga',
    '_north-west': 'North West',
    _northerncape: 'Northern Cape',
    _northernprovince: 'Limpopo',
    _westerncape: 'Western Cape',
    // Unitedstates
    _alabama: 'Alabama',
    _alaska: 'Alaska',
    _arizona: 'Arizona',
    _arkansas: 'Arkansas',
    _california: 'California',
    _colorado: 'Colorado',
    _connecticut: 'Connecticut',
    _delaware: 'Delaware',
    _districtofcolumbia: 'Washington, D.C.',
    _florida: 'Florida',
    _georgia: 'Georgia',
    _hawaii: 'Hawaii',
    _idaho: 'Idaho',
    _illinois: 'Illinois',
    _indiana: 'Indiana',
    _iowa: 'Iowa',
    _kansas: 'Kansas',
    _kentucky: 'Kentucky',
    _louisiana: 'Louisiana',
    _maine: 'Maine',
    _maryland: 'Maryland',
    _massachusetts: 'Massachusetts',
    _michigan: 'Michigan',
    _minnesota: 'Minnesota',
    _mississippi: 'Mississippi',
    _missouri: 'Missouri',
    _montana: 'Montana',
    _nebraska: 'Nebraska',
    _nevada: 'Nevada',
    _newhampshire: 'New Hampshire',
    _newjersey: 'New Jersey',
    _newmexico: 'New Mexico',
    _newyork: 'New York',
    _northcarolina: 'North Carolina',
    _northdakota: 'North Dakota',
    _ohio: 'Ohio',
    _oklahoma: 'Oklahoma',
    _oregon: 'Oregon',
    _pennsylvania: 'Pennsylvania',
    _rhodeisland: 'Rhode Island',
    _southcarolina: 'South Carolina',
    _southdakota: 'South Dakota',
    _tennessee: 'Tennessee',
    _texas: 'Texas',
    _utah: 'Utah',
    _vermont: 'Vermont',
    _virginia: 'Virginia',
    _washington: 'Washington',
    _westvirginia: 'West Virginia',
    _wisconsin: 'Wisconsin',
    _wyoming: 'Wyoming',
    // Uruguay
    _artigas: 'Artigas',
    _canelones: 'Canelones',
    _cerrolargo: 'Cerro Largo',
    _colonia: 'Colonia',
    _durazno: 'Durazno',
    _flores: 'Flores',
    // '_florida': 'Florida',
    _lavalleja: 'Lavalleja',
    _maldonado: 'Maldonado',
    _montevideo: 'Montevideo',
    _paysandú: 'Paysandú',
    _ríonegro: 'Río Negro',
    _rivera: 'Rivera',
    _rocha: 'Rocha',
    _salto: 'Salto',
    // '_sanjose': 'San José',
    _soriano: 'Soriano',
    _tacuaremb: 'Tacuarembó',
    _treinta: 'Treinta y Tres',
    // Bolivia
    _beni: 'Beni',
    _chuquisaca: 'Chuquisaca',
    _cochabamba: 'Cochabamba',
    _lapaz: 'La Paz',
    _oruro: 'Oruro',
    _pando: 'Pando',
    _potosí: 'Potosí',
    // '_santacruz': 'Santa Cruz',
    _tarija: 'Tarija',
    /* ** Ukraine ** */
    _cherkasy: 'Cherkasy',
    _chernihiv: 'Chernihiv',
    _chernivtsi: 'Chernivtsi',
    _dnipropetrovsk: 'Dnipropetrovsk',
    _donetsk: 'Donetsk',
    '_ivano-Frankivsk': 'Ivano-Frankivsk',
    _kharkiv: 'Kharkiv',
    _kherson: 'Kherson',
    _khmelnytskyi: 'Khmelnytskyi',
    _kiev: 'Kiev',
    _kirovohrad: 'Kirovohrad',
    _luhansk: 'Luhansk',
    _lviv: 'Lviv',
    _mykolaiv: 'Mykolaiv',
    _odessa: 'Odessa',
    _poltava: 'Poltava',
    _rivne: 'Rivne',
    _sumy: 'Sumy',
    _ternopil: 'Ternopil',
    _vinnytsia: 'Vinnytsia',
    _volyn: 'Volyn',
    _zakarpattia: 'Zakarpattia',
    _zaporizhia: 'Zaporizhia',
    _zhytomyr: 'Zhytomyr',
    // Lithuania
    _alytus: 'Alytus',
    _kaunas: 'Kaunas',
    _klaipėda: 'Klaipėda',
    _marijampolė: 'Marijampolė',
    _panevėžys: 'Panevėžys',
    _šiauliai: 'Šiauliai',
    _tauragė: 'Tauragė',
    _telšiai: 'Telšiai',
    _utena: 'Utena',
    _vilnius: 'Vilnius',
    // 英格兰
    _northumberland: 'Northumberland',
    _tyneandwear: 'Tyne and Wear',
    _durham: 'Durham',
    _cumbria: 'Cumbria',
    _lancashire: 'Lancashire',
    _blackpool: 'Blackpool',
    _blackburnwithdarwen: 'Blackburn with Darwen',
    _westyorkshire: 'West Yorkshire',
    _northyorkshire: 'North Yorkshire',
    _darlington: 'Darlington',
    _stocktononTees: 'Stockton-on-Tees',
    _middlesbrough: 'Middlesbrough',
    _hartlepool: 'Hartlepool',
    _redcarandcleveland: 'Redcar and Cleveland',
    _york: 'York',
    _eastridingofyorkshire: 'East Riding of Yorkshire',
    _hull: 'Hull',
    _northlincolnshire: 'North Lincolnshire',
    _northeastlincolnshire: 'North East Lincolnshire',
    _lincolnshire: 'Lincolnshire',
    _nottinghamshire: 'Nottinghamshire',
    _nottingham: 'Nottingham',
    _southyorkshire: 'South Yorkshire',
    _derbyshire: 'Derbyshire',
    _derby: 'Derby',
    _greatermanchester: 'Greater Manchester',
    _merseyside: 'Merseyside',
    _halton: 'Halton',
    _warrington: 'Warrington',
    _cheshire: 'Cheshire',
    _shropshire: 'Shropshire',
    _telfordandwrekin: 'Telford and Wrekin',
    _staffordshire: 'Staffordshire',
    _stokeonTrent: 'Stoke-on-Trent',
    _westmidlands: 'West Midlands',
    _warwickshire: 'Warwickshire',
    _leicestershire: 'Leicestershire',
    _leicester: 'Leicester',
    _rutland: 'Rutland',
    _northamptonshire: 'Northamptonshire',
    _peterborough: 'Peterborough',
    _cambridgeshire: 'Cambridgeshire',
    _norfolk: 'Norfolk',
    _suffolk: 'Suffolk',
    _essex: 'Essex',
    _southendonSea: 'Southend-on-Sea',
    _thurrock: 'Thurrock',
    _hertfordshire: 'Hertfordshire',
    _bedfordshire: 'Bedfordshire',
    _luton: 'Luton',
    _miltonkeynes: 'Milton Keynes',
    _buckinghamshire: 'Buckinghamshire',
    _oxfordshire: 'Oxfordshire',
    _gloucestershire: 'Gloucestershire',
    _worcestershire: 'Worcestershire',
    _herefordshire: 'Herefordshire',
    _southgloucestershire: 'South Gloucestershire',
    _bristol: 'Bristol',
    _northsomerset: 'North Somerset',
    _bathandnortheastsomerset: 'Bath and North East Somerset',
    _wiltshire: 'Wiltshire',
    _swindon: 'Swindon',
    _berkshire: 'Berkshire',
    _greaterlondon: 'Greater London',
    _medway: 'Medway',
    _kent: 'Kent',
    _eastsussex: 'East Sussex',
    _brightonandhove: 'Brighton and Hove',
    _westsussex: 'West Sussex',
    _surrey: 'Surrey',
    _hampshire: 'Hampshire',
    _southampton: 'Southampton',
    _portsmouth: 'Portsmouth',
    _isleofwight: 'Isle of Wight',
    _dorset: 'Dorset',
    _poole: 'Poole',
    _bournemouth: 'Bournemouth',
    _somerset: 'Somerset',
    _devon: 'Devon',
    _torbay: 'Torbay',
    _plymouth: 'Plymouth',
    _cornwall: 'Cornwall',
    // 苏格兰
    _dumfriesandgalloway: 'Dumfries and Galloway',
    _scottishborders: 'Scottish Borders',
    _midlothian: 'Midlothian',
    _eastlothian: 'East Lothian',
    _edinburgh: 'Edinburgh',
    _westlothian: 'West Lothian',
    _southlanarkshire: 'South Lanarkshire',
    _eastayrshire: 'East Ayrshire',
    _southayrshire: 'South Ayrshire',
    _northayrshire: 'North Ayrshire',
    _inverclyde: 'Inverclyde',
    _renfrewshire: 'Renfrewshire',
    _eastrenfrewshire: 'East Renfrewshire',
    _glasgow: 'Glasgow',
    _northlanarkshire: 'North Lanarkshire',
    _falkirk: 'Falkirk',
    _eastdunbartonshire: 'East Dunbartonshire',
    _westdunbartonshire: 'West Dunbartonshire',
    _argyllandbute: 'Argyll and Bute',
    _stirling: 'Stirling',
    _clackmannanshire: 'Clackmannanshire',
    _fife: 'Fife',
    _dundee: 'Dundee',
    _angus: 'Angus',
    _perthandkinross: 'Perth and Kinross',
    _aberdeenshire: 'Aberdeenshire',
    _aberdeen: 'Aberdeen',
    _moray: 'Moray',
    _highland: 'Highland',
    _eileansiar: 'Eilean Siar',
    _orkney: 'Orkney',
    _shetland: 'Shetland',
    // 威尔士
    _merthyrtydfil: 'Merthyr Tydfil',
    _caerphilly: 'Caerphilly',
    _blaenaugwent: 'Blaenau Gwent',
    _torfaen: 'Torfaen',
    _monmouthshire: 'Monmouthshire',
    _newport: 'Newport',
    _cardiff: 'Cardiff',
    _valeofglamorgan: 'Vale of Glamorgan',
    _bridgend: 'Bridgend',
    _rhonddacynontaff: 'Rhondda Cynon Taff',
    _neathporttalbot: 'Neath Port Talbot',
    _swansea: 'Swansea',
    _carmarthenshire: 'Carmarthenshire',
    _ceredigion: 'Ceredigion',
    _powys: 'Powys',
    _wrexham: 'Wrexham',
    _flintshire: 'Flintshire',
    _denbighshire: 'Denbighshire',
    _conwy: 'Conwy',
    _gwynedd: 'Gwynedd',
    _isleofanglesey: 'Isle of Anglesey',
    _pembrokeshire: 'Pembrokeshire',
    // 北爱尔兰
    _alds: 'Alds',
    _kastleleagh: 'Kastleleagh',
    _down: 'Down',
    _northdown: 'North Down',
    _banbridge: 'Banbridge',
    _belfast: 'Belfast',
    _lisburn: 'Lisburn',
    _antrim: 'Antrim',
    _ballymena: 'Ballymena',
    _ballymoney: 'Ballymoney',
    _carrifergus: 'Carrifergus',
    _larne: 'Larne',
    _moyle: 'Moyle',
    _newtownabbey: 'Newtownabbey',
    _armagh: 'Armagh',
    _klaigavon: 'Klaigavon',
    _newlyandmoulne: 'Newly and Moulne',
    _coleraine: 'Coleraine',
    _maghelafelt: 'Maghelafelt',
    _derry: 'Derry',
    _limavady: 'Limavady',
    _cookstown: 'Cookstown',
    _dungannon: 'Dungannon',
    _omagh: 'Omagh',
    _stlabane: 'Stlabane',
    _fermanagh: 'Fermanagh',
    // 俄罗斯
    _adygea: 'Adygea',
    _altai: 'Altai',
    _amur: 'Amur',
    _arkhangelsk: 'Arkhangelsk',
    _astrakhan: 'Astrakhan',
    _bashkortostan: 'Bashkortostan',
    _belgorod: 'Belgorod',
    _bryansk: 'Bryansk',
    _buryatia: 'Buryatia',
    _chechnya: 'Chechnya',
    _chelyabinsk: 'Chelyabinsk',
    _chukotka: 'Chukotka',
    _chuvashia: 'Chuvashia',
    _crimea: 'Crimea',
    _dagestan: 'Dagestan',
    _ingushetia: 'Ingushetia',
    _irkutsk: 'Irkutsk',
    _ivanovo: 'Ivanovo',
    _jewish: 'Jewish',
    _kabardinoBalkaria: 'Kabardino-Balkaria',
    _kaliningrad: 'Kaliningrad',
    _kalmykia: 'Kalmykia',
    _kaluga: 'Kaluga',
    _kamchatka: 'Kamchatka',
    _karachayCherkessia: 'Karachay-Cherkessia',
    _karelia: 'Karelia',
    _kemerovo: 'Kemerovo',
    _khabarovsk: 'Khabarovsk',
    _khakassia: 'Khakassia',
    _khantyMansi: 'Khanty-Mansi',
    _kirov: 'Kirov',
    _komi: 'Komi',
    _kostroma: 'Kostroma',
    _krasnodar: 'Krasnodar',
    _krasnoyarsk: 'Krasnoyarsk',
    _kurgan: 'Kurgan',
    _kursk: 'Kursk',
    _leningrad: 'Leningrad',
    _lipetsk: 'Lipetsk',
    _magadan: 'Magadan',
    _mariEl: 'Mari El',
    _mordovia: 'Mordovia',
    _moscow: 'Moscow',
    _murmansk: 'Murmansk',
    _nenets: 'Nenets',
    _nizhnyNovgorod: 'Nizhny Novgorod',
    _northOssetiaAlania: 'North Ossetia–Alania',
    _novgorod: 'Novgorod',
    _novosibirsk: 'Novosibirsk',
    _omsk: 'Omsk',
    _orenburg: 'Orenburg',
    _oryol: 'Oryol',
    _penza: 'Penza',
    _perm: 'Perm',
    _primorsky: 'Primorsky',
    _pskov: 'Pskov',
    _rostov: 'Rostov',
    _ryazan: 'Ryazan',
    _sakhalin: 'Sakhalin',
    _samara: 'Samara',
    _saratov: 'Saratov',
    _sevastopol: 'Sevastopol',
    _smolensk: 'Smolensk',
    _stPetersburg: 'St. Petersburg',
    _stavropol: 'Stavropol',
    _sverdlovsk: 'Sverdlovsk',
    _tambov: 'Tambov',
    _tatarstan: 'Tatarstan',
    _tomsk: 'Tomsk',
    _tula: 'Tula',
    _tuva: 'Tuva',
    _tver: 'Tver',
    _tyumen: 'Tyumen',
    _udmurtia: 'Udmurtia',
    _ulyanovsk: 'Ulyanovsk',
    _vladimir: 'Vladimir',
    _volgograd: 'Volgograd',
    _vologda: 'Vologda',
    _voronezh: 'Voronezh',
    _yakutia: 'Yakutia',
    _yamaloNenets: 'Yamalo-Nenets',
    _yaroslavl: 'Yaroslavl',
    _zabaykalsky: 'Zabaykalsky',
    // 奥地利
    _kärnten: 'Kärnten',
    _oberösterreich: 'Oberösterreich',
    _steiermark: 'Steiermark',
    // 丹麦
    _esbjerg: 'Esbjerg',
    _frederikshavn: 'Frederikshavn',
    _herning: 'Herning',
    _horsens: 'Horsens',
    _ringsted: 'Ringsted',
    _rønne: 'Rønne',
    _sønderborg: 'Sønderborg',
    _thisted: 'Thisted',
    _vejen: 'Vejen',
    _vejle: 'Vejle',
    // 芬兰
    _länsi_suomi: 'Länsi-Suomi',
    // 德国
    _baden_württemberg: 'Baden-Württemberg',
    _brandenburg: 'Brandenburg',
    _freistaat_bayern: 'Freistaat Bayern',
    _lower_saxony: 'Lower-Saxony',
    _nordrhein_westfalen: 'Nordrhein-Westfalen',
    _rheinland_pfalz: 'Rheinland-Pfalz',
    _sachsen_anhalt: 'Sachsen-Anhalt',
    // 意大利
    _co: 'CO',
    _cr: 'CR',
    _mn: 'MN',
    _mo: 'MO',
    _pr: 'PR',
    // 葡萄牙
    _evora: 'Évora',
    _leiria: 'Leiria',
    _lisboa: 'Lisboa',
    _santarém: 'Santarém',
    _setúbal: 'Setúbal',
    // 西班牙
    _badajoz: 'Badajoz',
    _barcelona: 'Barcelona',
    _burgos: 'Burgos',
    _cuenca: 'Cuenca',
    _girona: 'Girona',
    _huelva: 'Huelva',
    _huesca: 'Huesca',
    _las_palmas: 'Las Palmas',
    _león: 'León',
    _lérida: 'Lérida',
    _madrid: 'Madrid',
    _málaga: 'Málaga',
    _murcia: 'Murcia',
    _pontevedra: 'Pontevedra',
    _salamanca: 'Salamanca',
    _toledo: 'Toledo',
    _zaragoza: 'Zaragoza',
    // 瑞士
    _basel_stadt: 'Basel-Stadt',
    _bern: 'Bern',
    _solothurn: 'Solothurn',
    // Login
    termsandconditions: 'Terms & Conditions',
    privacypolicy: 'Privacy Policy',
    login: 'Login',
    username: 'Username',
    password: 'Password',
    captcha: 'Captcha',
    remeberme: 'Remember me',
    forgetpwd: 'Forgot Password',
    register: 'Register',
    // Pagination
    paginationpage: 'Page',
    paginationfirst: 'First',
    paginationlast: 'Last',
    paginationprevious: 'Previous',
    paginationnext: 'Next',
    // 试用权限提示
    trialtipprefix: 'You have access to view ',
    trialtipsuffix: ' pieces of information under Free Trial.',
    closebtn: 'Close',
    confirmbtn: 'OK',
    // 个人中心
    newpwd: 'New Password',
    renewpwd: 'Re-enter New Password',
    // 缴费页面
    chargetips: 'Please ensure that your contact information and company details are actual and valid. In order to avoid any inconveniences, you can modify your registration in "My BTC Account".',
    companyname: 'Company Name',
    contactperson: 'Contact Person',
    gender: 'Gender',
    mobile: 'Mobile',
    email: 'Email',
    gendermr: 'Mr',
    genderms: 'Ms',
    membershipapply: 'Apply for BTC Membership',
    membershiptryapply: 'Apply for Free Trial',
    _vip_member_en: '',
    _vip_member: '',
    _gold_member: '',
    _platinum_member: '',
    _porktochina_member: '',
    sendrequest: 'Send',
    cancelrequest: 'Cancel',
    memberpayreqsuccessful: 'Sent successfully. BTC may contact with you via email or phone. If you have any questions, please email contact@beeftochina.com.',
    membertryreqsuccessful: 'Sent successfully. BTC may contact with you via email or phone. If you have any questions, please email contact@beeftochina.com.',
    // 忘记密码
    resetpwd: 'Reset Password',
    forgetwdname: 'Please enter your username',
    forgetwdemail: 'Please enter your email',
    nouser: 'This user does not exist',
    useremailnotmatch: 'Username and email do not match',
    mailmsg: '<p style="color: #014379; font-size: 18px; font-weight: bold;margin: 20px 0;">Sent Successfully<p/><p style="color: #666666;font-size: 16px;margin-bottom: 50px">A secure link has been sent to your registered email. Please follow the link to reset your password.</p>',
    sendEmail: 'Your message has been sent to Chinese beef buyers, please check the reply in your BTC Account.',
    resetsuccessful: 'Reset Successfully',
    newpwdnotempty: 'The new password cannot be empty',
    newpwdrule: 'The new password must be between 6-20 characters long, only letters and numbers are allowed.',
    repwdnotempty: 'Please re-enter your password.',
    repwdnotmatch: 'Password does not match',
    pwdrepeat: 'The new password cannot be the same as the original password',
    dateMsg: 'The deadline shall not be earlier than the beginning time.',
    // 注册
    regsuccessful: '<p style="color: #014379; font-size: 18px; font-weight: bold;margin: 20px 0;">Thanks for your registration.<p/><p style="color: #666666;font-size: 16px;margin-bottom: 50px">Now you can view more information on BTC. BTC may call or email you for confirmation purpose. Please ensure that your contact information and company details are actual and valid. Kindly understand that some accounts might be blocked, in the event that their registration are not clearly identified. In order to avoid any inconvenience, our Users can modify their registration in ‘My BTC Account’, if needed. Thanks for your patience!</p>',
    // 开通会员
    sentmembershipsful: '<p style="color: #014379; font-size: 18px; font-weight: bold;margin: 20px 0;">Sent Successfully<p/><p style="color: #666666;font-size: 16px;margin-bottom: 50px">BTC may contact with you via email or phone. If you have any questions, please email <a>membership@beeftochina.com</a>.</p>',
    // 新闻列表
    largeview: 'Large View',
    smallview: 'Small View',
    readmore: 'Read More',
    morenews: 'More',
    country: 'Country',
    category: 'Category',
    keyword: 'Keyword',
    // 新闻详情
    publishtime: 'Published on: ',
    needlogin: 'Login for more information...',
    copylink: 'Copy the link',
    copysuccess: 'Copy Successfully!',
    copyfailed: 'Copy Failed!',
    // 验证码
    captchatimeout: 'The captcha is invalid',
    captchaerr: 'Wrong captcha',
    error: 'System error',
    contactemailsendok: 'Sent Successfully',
    savedsuccessfully: 'Saved Successfully',
    wrongpwd: 'Failed to change password. Your current password is incorrect.',
    other: 'Other',
    _source_of_origin_subtype: 'By Origin',
    _customs_subtype: 'By Customs',
    _province_subtype: 'By Province',
    _chinese_importer_subtype: 'By Importers',
    _beef_cuts_subtype: 'By Cuts',
    _exporters_subtype: 'By Exporters',
    _hongkong_subtype: 'Hong Kong',
    _india_subtype: 'India',
    _other_subtype: 'Other',
    // 登录
    userpwdnotmatch: 'Username or Password error',
    _othercountry: 'Other',
    // 行情走势部位代码
    _nn80vl: 'Flank 80vl',
    _nbr: 'Neck',
    _jbr: 'Shoulder',
    _qx: 'Brisket Point End',
    _hx: 'Brisket Navel End',
    _nl97vl: 'Knuckle 97vl',
    _nq90vl: 'Forequarter 90vl',
    _dml97vl: 'Outside Flat 97vl',
    _qj: 'Shin',
    _hj: 'Shank',
    _gj: 'Heel Muscle',
    _xl3d5kgj: 'Striploin 3.5kg+',
    _yr2d5kgj: 'Cube Roll 2.5kg+',
    _tr97vl: 'Topside 97vl',
    _xml97vl: 'Eyeround 97vl',
    _blj: 'Bolar Blade',
    _njr: 'Chuck Tender',
    _sr80vl: 'Trimmings 80vl',
    _qcb: 'Navel Plate',
    _xl3d0kgj: 'Striploin 3.0kg+',
    _sndbr: 'Chuck with Neck',
    _qn: 'Full sets',
    _nq5jt: 'Forequarter 5 Cuts',
    _nq7jt: 'Forequarter 7 Cuts',
    // 澳新
    _sr80cl: 'Trimmings 80cl',
    _nj: 'Shin&Shank',
    _cxdml: 'Outside Flat',
    _snx: 'Chuck Roll',
    _nl: 'Knuckle',
    _bj: 'Oyster Blade',
    _nfr: 'Thin Flank',
    _xl: 'Striploin',
    _xml: 'Eyeround',
    _tr: 'Topside',
    _dgsft: 'Bone-in Quarters',
    _nqhbwr65cl: 'Fore and Hind Quarters 65cl',
    _bfbjxdml: 'Outside Flat 100vl',
    _dgnxp: 'Bone-in Short Ribs',
    _sjjr: 'Clod',
    _sn: 'Chuck',
    _bfbjxxml: 'Eyeround 100vl',
    _yrx: 'Cube Roll',
    // 新增
    _nq4jt: 'Forequarter 4 Cuts',
    _nq6jt: 'Forequarter 6 Cuts',
    _nq8jt: 'Forequarter 8 Cuts',
    _jxtr: 'Inside Meat',
    _tyrx: 'Heart of Rump',
    _tyr: 'Rump',
    '_yrx2kg+': 'Cube Roll 2kg+',
    _qnhgt: 'Full Sets (Bone-in)',
    _dgxr: 'Bone-in Brisket',
    _qhj: 'Shin&Shank',
    _jjbj: 'Chuck, Neck and Blade',
    _sr70vl: 'Trimmings 70vl',
    _dgtr: 'Topside Cap on',
    _58l: 'Rib 5/8',
    _dgnn: 'Bone-in Flank',
    _qn23q: 'Compensated 23 Cuts',
    _snjj: 'Chuck and Blade',
    _trg: 'Topside Cap',
    _xr: 'Brisket',
    _544l: 'Rib 5/4/4',
    _yrg: 'Cube Roll Cap',
    _yr: 'Cube Roll',
    _qn6q: 'Compensated 6 Cuts',
    _jjdsn: 'Chuck and Blade',
    _jjr: 'Blade',
    _tw: 'Rump Tail',
    _tf: 'Hump',
    _tyrdtw: 'Rump with Tail',
    '_lp5+5': 'Rib 5/5',
    _nnp: 'Flank Steak',
    // 行情走势国家代码
    argentina: 'Argentina',
    brazil: 'Brazil',
    uruguay: 'Uruguay',
    australia: 'Australia',
    newzealand: 'New Zealand',
    _serious: 'serious',
    _justajoke: 'justajoke',
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',

    // 权限相关
    _beefnewsandevents_en: '_beefnewsandevents_en',
    _beefeyes: '_beefeyes_en',
    _beefreports_en: '_beefreports_en',
    _btcdata_en: '_btcdata_en',
    _beefprice_en: '_beefprice_en',
    _beefmarket_en: '_beefmarket_en',
    _exoress_en: '_exoress_en',
    _analysis_en: '_analysis_en'
  }
}
