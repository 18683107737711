<template>
  <b-modal id="CnMembershipsApplyDialog" @shown="getUserInfo">
    <div class="box-items">
      <div class="box-item1">
        <img src="@/assets/image/cn/vip.png" alt="">
        <div>申请加入必孚会员</div>
      </div>
      <div class="box-item2">
        请确保您的如下联系方式和公司信息的真实有效性，如有变更，请前往“个人中心”进行修改。
      </div>
      <div class="box-item3">
        <div>
          <div>
            <label for="CompanyName">公司名称</label>
            <input v-model="chargeForm.CompanyName" type="text" placeholder="Enter your user name" id="CompanyName" class="form-control" disabled>
          </div>
          <div>
            <label for="ContactPersonName">联系人</label>
            <input v-model="chargeForm.ContactPersonName" type="text" placeholder="Enter your user name" id="ContactPersonName" class="form-control" disabled>
          </div>
        </div>
        <div>
          <div>
            <label for="ContactPersonSex">性别</label>
            <input v-model="chargeForm.ContactPersonSex" type="text" placeholder="Enter your user name" id="ContactPersonSex" class="form-control" disabled>
          </div>
          <div>
            <label for="CompanyPhoneNumber">手机</label>
            <input v-model="chargeForm.CompanyPhoneNumber" type="text" placeholder="Enter your user name" id="CompanyPhoneNumber" class="form-control" disabled>
          </div>
        </div>
        <div>
          <div>
            <label for="CompanyEmail">邮箱</label>
            <input v-model="chargeForm.CompanyEmail" type="text" placeholder="Enter your user name" id="CompanyEmail" class="form-control" disabled>
          </div>
        </div>
      </div>
      <div class="box-item4">
        <button type="button" class="btn btn-two" @click.prevent="sendRequest">提交</button>
        <button type="button" class="btn btn-three" @click="$bvModal.hide('CnMembershipsApplyDialog')">取消</button>
      </div>
<!--      <div class="box-item5">-->
<!--        <img src="@/assets/image/login/query.png" alt="" style="width: 16px;height: 16px;margin-right: -15px;margin-top: 4px">-->
<!--        <div class="hand box-item5-one" @click="read(url)">如何成为BTC会员?</div>-->
<!--        <div><span class="hand box-item5-two" @click="handleContactUs">Leave message</span> or email <a class="box-item5-a" href="mailto:mebership@beeftochina.com">membership@beeftochina.com</a></div>-->
<!--      </div>-->
    </div>
  </b-modal>
</template>

<script>
import { getLocalStore } from '@/utils/common'

export default {
  components: {},
  data () {
    return {
      chargeForm: {},
      url: `${process.env.VUE_APP_STATIC}CnPlantIcon/pdf/BTCMembership.pdf`
    }
  },
  mounted () {},
  methods: {
    getUserInfo () {
      this.$store.dispatch('GetUserInfo', getLocalStore().UserID).then(() => {
        this.chargeForm = this.$store.getters.userRow
        this.chargeForm.ContactPersonSex = this.$t(`message.${this.chargeForm.ContactPersonSex}`)
      })
    },
    sendRequest () {
      this.chargeForm.UserID = getLocalStore().UserID
      this.chargeForm.UserName = this.chargeForm.ContactPersonName
      this.chargeForm.RequireLevel = this.$store.getters.userow.RequireLevel
      this.chargeForm.RequireType = this.$store.getters.userow.RequireType
      this.chargeForm.RequireTypeT = this.$store.getters.userow.RequireTypeT
      this.chargeForm.isCnRequire = this.$store.getters.userow.isCnRequire
      this.chargeForm.isEnRequire = this.$store.getters.userow.isEnRequire
      this.$store.dispatch('MembershipRequest', this.chargeForm).then(() => {
        this.$store.dispatch('SetMemberReqType', this.chargeForm.RequireType)
        this.$bvModal.hide('CnMembershipsApplyDialog')
        this.$bvModal.show('sentMembershipsDialog')
      }).catch(() => {
      })
    },
    handleContactUs () {
      this.$bvModal.hide('CnMembershipsApplyDialog')
      this.$router.push('/cn/ContactUs')
    },
    read (url) {
      window.open(url)
    }
  }
}
</script>

<style>
#CnMembershipsApplyDialog{
  padding: 0;
}

#CnMembershipsApplyDialog.modal{
  height: auto;
  top: unset;
  bottom: 0!important;
}
#CnMembershipsApplyDialog .modal-dialog {
  background-color: #fff0;
  max-width: 100vw;
  margin: 0;
  padding: 0;
}

#CnMembershipsApplyDialog .modal-content {
  background-color: #fff0;
  border: none;
}

#CnMembershipsApplyDialog .modal-content .close {
  color: #ffffff;
  font-size: 50px;
}

#CnMembershipsApplyDialog .modal-header {
  height: 96px;
  width: 960px;
  background-position:50% 50% ;
  background-size: 200%;
  background-image:url(../../../assets/image/login/memberships-head.png);
  background-repeat:no-repeat;
  margin-left: 480px;
  margin-bottom: -20px;
  border: none;
  padding: 0;
  z-index: 999;
}

#CnMembershipsApplyDialog .modal-body {
  height: 717px;
  background-position:50% 50% ;
  background-size: 100%;
  background-image:url(../../../assets/image/login/memberships-background.png);
  background-repeat:no-repeat;
  padding: 0;
}

#CnMembershipsApplyDialog .modal-footer {
  display: none;
}

@media screen and (min-width: 1920px) {
  #CnMembershipsApplyDialog .modal-header {
    height: 96px;
    width: 960px;
    margin-left: 480px;
  }
  #CnMembershipsApplyDialog .modal-body {
    height: 717px;
  }
}

@media screen and (min-width: 2048px) {
  #CnMembershipsApplyDialog .modal-header {
    height: 102px;
    width: 1024px;
    margin-left: 721px;
  }
  #CnMembershipsApplyDialog .modal-body {
    height: 950px;
  }
}
</style>

<style scoped>
.box-items{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: flex-start;
}
.box-item1{
  margin: 30px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  font-size: 18px;
  font-weight: bold;
  color: #014379;
}

.box-item1 > img{
  width: 39px;
  height: 36px;
}

.box-item2{
  width: 747px;
  text-align: center;
  margin-bottom: 50px;
  color: #666666;
  font-size: 16px;
}
.box-item3{
  display: flex;
  width: 552px;
  flex-direction: column;
  color: #535353;
  font-size: 16px;
}
.box-item3 > div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.box-item3 > div > div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.box-item4{
  width: 552px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 50px;
}

.btn-two {
  width: 295px;
  height: 35px;
  border-radius: 8px;
  background-color: #4285F4;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.btn-three {
  width: 168px;
  height: 35px;
  border-radius: 8px;
  background-color: #2054A9;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.btn:hover{
  color: #ffffff;
}

.box-item5{
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-content: center;
  margin-top: 50px;
}

.box-item5-one{
  height: 20px;
  color: #4285F4;
  margin-left: 20px;
}

.box-item5-one:hover{
  border-bottom: 1px solid #000000;
}

.box-item5-two{
  margin-left: 25px;
}

.box-item5-a{
  color: #000000;
  text-decoration: none;
}

input {
  width: 250px;
  height: 35px;
  background-color: #EDEDED;
  border-radius: 8px;
  border: 1px solid #EDEDED;
  padding: 0 15px;
  font-size: 12px;
}

/*占位符修改*/
input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 10px;
  color: #CCCCCC;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 10px;
  color: #CCCCCC;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-size: 10px;
  color: #CCCCCC;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-size: 10px;
  color: #CCCCCC;
}
</style>
