import api from '@/api/etc'

export default {
  state: {
    total: 0,
    row: {},
    rows: [],
    approvePlantOrigin: {},
    approvePlantPlantNo: {},
    approvePlantProvince: {},
    approvePlantData: [],
    approvePlantPattern: []
  },

  mutations: {
    SET_TOTAL: (state, total) => {
      state.total = total
    },
    SET_ROW: (state, row) => {
      state.row = row
    },
    SET_ROWS: (state, rows) => {
      state.rows = rows
    },
    SET_APPROVE_PLANT_ORIGIN: (state, approvePlantOrigin) => {
      state.approvePlantOrigin = approvePlantOrigin
    },
    SET_APPROVE_PLANT_PLANTNO: (state, approvePlantPlantNo) => {
      state.approvePlantPlantNo = approvePlantPlantNo
    },
    SET_APPROVE_PLANT_PROVINCE: (state, approvePlantProvince) => {
      state.approvePlantProvince = approvePlantProvince
    },
    SET_APPROVE_PLANT_DATA: (state, approvePlantData) => {
      state.approvePlantData = approvePlantData
    },
    SET_APPROVE_PLANT_PATTERN: (state, approvePlantPattern) => {
      state.approvePlantPattern = approvePlantPattern
    }
  },
  actions: {
    AddWebPlantSearchInfo ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.AddWebPlantSearchInfo(params).then(response => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebApprovePlantStateOrigin ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebApprovePlantStateOrigin(params).then(response => {
          commit('SET_ROW', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebApprovePlantStatePlantNO ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebApprovePlantStatePlantNO(params).then(response => {
          commit('SET_ROW', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebApprovePlantChildState ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebApprovePlantChildState(params).then(response => {
          commit('SET_ROWS', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebApprovePlantState ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebApprovePlantState(params).then((response) => {
          commit('SET_ROWS', response.Data.Rows)
          commit('SET_TOTAL', response.Data.Total)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetWebFrontApprovePlantOrigin ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebFrontApprovePlantOrigin(params).then((response) => {
          commit('SET_APPROVE_PLANT_ORIGIN', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 厂号
    GetWebFrontApprovePlantNo ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebFrontApprovePlantNo(params).then((response) => {
          commit('SET_APPROVE_PLANT_PLANTNO', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 查询准入工厂省份
    GetWebFrontApprovePlantProvince ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebFrontApprovePlantProvince(params).then((response) => {
          commit('SET_APPROVE_PLANT_PROVINCE', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 查询准入工厂省份
    GetWebFrontApprovePlant ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebFrontApprovePlant(params).then((response) => {
          commit('SET_APPROVE_PLANT_DATA', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 查询准入工厂格局
    GetWebFrontApprovePlantPattern ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebFrontApprovePlantPattern(params).then((response) => {
          commit('SET_APPROVE_PLANT_PATTERN', response.Data.Patterns)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 查询历史数据
    GetWebHistoricalUpdate ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebHistoricalUpdate(params).then((response) => {
          commit('SET_ROWS', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
