import api from '@/api/login'
import { setToken, getToken, removeToken } from '@/utils/auth'
import { getLocalStore, setLocalStore, delLocalStore, clearStore } from '@/utils/common'

export default {
  state: {
    temp: {},
    userAvator: '',
    token: getToken(),
    name: getLocalStore().UserID,
    row: {},
    userRow: {},
    type: '',
    isExpire: getLocalStore().isExpire,
    isEnExpire: getLocalStore().isEnExpire,
    isClear: false,
    isClose: false,
    userInfo: {},
    profile: {},
    redDot: false
  },

  mutations: {
    SET_TEMP: (state, temp) => {
      state.temp = temp
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_ROW: (state, row) => {
      state.row = row
    },
    SET_PROFILE: (state, profile) => {
      state.profile = profile
    },
    SET_USERROW: (state, userRow) => {
      state.userRow = userRow
    },
    SET_TYPE: (state, type) => {
      state.type = type
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_ISEXPIRE: (state, expireDate, chargeState) => {
      if (expireDate) {
        state.isExpire = ((new Date(expireDate).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) <= 30
        setLocalStore({ isExpire: state.isExpire && chargeState === 3 })
      }
    },
    SET_ISENEXPIRE: (state, enExpireDate, chargeState) => {
      if (enExpireDate) {
        state.isEnExpire = ((new Date(enExpireDate).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) <= 30
        setLocalStore({ isEnExpire: state.isEnExpire && chargeState === 3 })
      }
    },
    SET_AVATOR: (state, userAvator) => {
      state.userAvator = userAvator
    },
    SET_REDDOT: (state, redDot) => {
      state.redDot = redDot
    }
  },

  actions: {
    SetUserTemp ({ commit }, temp) {
      commit('SET_TEMP', temp)
    },
    // 会员申请参数
    SetMembershipReqParams ({ commit }, params) {
      commit('SET_ROW', params)
    },
    // 会员申请提示类别
    SetMemberReqType ({ commit }, params) {
      commit('SET_TYPE', params)
    },
    // 清空token
    clearToken ({ commit }) {
      clearStore()
      delLocalStore()
      removeToken()
      commit('SET_TOKEN', '')
    },
    // 登录
    Login ({ commit }, userInfo) {
      const username = userInfo.UserName.trim()
      return new Promise((resolve, reject) => {
        api.Login(username, userInfo.Password).then(response => {
          setToken(response.Data.Token)
          commit('SET_TOKEN', response.Data.Token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 注册
    Signup ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        api.UserSignUp(userInfo).then(response => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户信息
    GetUserInfo ({ commit }, username) {
      return new Promise((resolve, reject) => {
        api.GetUserInfo(username).then(response => {
          commit('SET_USERROW', response.Data.Row)
          commit('SET_USERINFO', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户权限
    GetUserProfile ({ commit }, username) {
      return new Promise((resolve, reject) => {
        api.GetUserProfile(username).then(response => {
          setLocalStore(response.Data.Row)
          commit('SET_PROFILE', response.Data.Row)
          commit('SET_NAME', response.Data.Row.UserID)
          commit('SET_ISEXPIRE', response.Data.Row.ChargeExpireDate, response.Data.Row.ChargeState)
          commit('SET_ISENEXPIRE', response.Data.Row.ChargeEnExpireDate, response.Data.Row.ChargeStataEn)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 修改密码
    ChangePassword ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.ChangePassword(params).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 修改用户信息
    UpdateUserInfo ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.UpdateUserInfo(params).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 会员资格申请
    MembershipRequest ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.MembershipRequest(params).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取验证码
    GetCaptcha ({ commit }, mobile) {
      return new Promise((resolve, reject) => {
        api.GetCaptcha(mobile).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 检查验证码
    CheckCaptcha ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.CheckCaptcha(params).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 发送邮件
    SendMail ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.SendMail(params).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 找回密码
    ForgetWebPassword ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.ForgetWebPassword(params).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 重置密码验证
    ResetPasswordByEmail ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.ResetPasswordByEmail(params).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 用户名查重
    CheckUserName ({ commit }, username) {
      return new Promise((resolve, reject) => {
        api.CheckUserName(username).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 邮箱查重
    CheckRegEmail ({ commit }, code) {
      return new Promise((resolve, reject) => {
        api.CheckRegEmail(code).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取微信ticket
    GetWxTicket ({ commit }) {
      return new Promise((resolve) => {
        api.GetWxTicket().then(response => {
          resolve(response.Data.Row)
        })
      })
    },
    // 手机号查重
    CheckDuplicatePhone ({ commit }, phone) {
      return new Promise((resolve, reject) => {
        api.CheckDuplicatePhone(phone).then(response => {
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 通过token获取用户信息
    GetUserProfileByToken ({ commit }, token) {
      return new Promise((resolve, reject) => {
        api.GetUserProfileByToken(token).then(response => {
          const profile = response.Data.Row.profile
          setToken(token)
          setLocalStore(profile)
          commit('SET_TOKEN', token)
          commit('SET_ROW', profile)
          commit('SET_NAME', profile.UserID)
          commit('SET_ISEXPIRE', profile.ChargeExpireDate, profile.ChargeState)
          commit('SET_ISENEXPIRE', profile.ChargeEnExpireDate, profile.ChargeStataEn)
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
    },
    GetFrontUserInfoByUserID ({ commit }, phone) {
      return new Promise((resolve, reject) => {
        api.GetFrontUserInfoByUserID(phone).then(response => {
          commit('SET_ROW', response.Data.Row)
          commit('SET_AVATOR', response.Data.Row.Avator)
          commit('SET_REDDOT', response.Data.Row.RedDot)
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
    },
    UpdateUserActivityByToken ({ commit }, token) {
      return new Promise((resolve, reject) => {
        api.UpdateUserActivityByToken(token).then(response => {
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
    },
    GetCaptchaImage ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        api.GetCaptchaImage().then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    UploadAvator ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.UploadAvator(params).then((response) => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
