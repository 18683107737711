import api from '@/api/news'

export default {
  state: {
    row: {},
    rows: [],
    total: 0,
    newsid: 0
  },

  mutations: {
    SET_ROW: (state, row) => {
      state.row = row
    },
    SET_ROWS: (state, rows) => {
      state.rows = rows
    },
    SET_TOTAL: (state, total) => {
      state.total = total
    },
    SET_NEWSID: (state, newsid) => {
      state.newsid = newsid
    }
  },

  actions: {
    GetBtcInfoHeadlines ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBtcInfoHeadlines(params).then(response => {
          commit('SET_ROWS', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    GetBtcInfoDetail ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBtcInfoDetail(params).then(response => {
          commit('SET_ROW', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    GetBtcInfoRecommendation ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBtcInfoRecommendation(params).then(response => {
          commit('SET_ROWS', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    GetBtcInfoNewsBySection ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBtcInfoNewsBySection(params).then(response => {
          commit('SET_ROWS', response.Data.Rows)
          commit('SET_TOTAL', response.Data.Total)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    GetBeefInfoReport ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefInfoReport(params).then(response => {
          commit('SET_ROW', response.Data.Row)
          commit('SET_ROWS', response.Data.Rows)
          commit('SET_TOTAL', response.Data.Total)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    GetBeefInfoReportDate ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefInfoReportDate(params).then(response => {
          commit('SET_ROW', response.Data.Dates)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    GetBeefInfoReportByDate ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetBeefInfoReportByDate(params).then(response => {
          commit('SET_ROWS', response.Data.Datas)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    GetWebCollection ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.GetWebCollection(params).then(response => {
          commit('SET_ROWS', response.Data.Rows)
          commit('SET_TOTAL', response.Data.Total)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    GetAppNewsDetail ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.getAppNewsDetail(params).then((response) => {
          commit('SET_ROW', response.Data.Row)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    GetSimilarArticle ({ commit }, params) {
      return new Promise((resolve, reject) => {
        api.getSimilarArticle(params).then((response) => {
          commit('SET_ROWS', response.Data.Rows)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
