export const cn = {
  message: {
    _restore: '恢复输华',
    _china_pause: '中方暂停',
    _self_pause: '自主暂停',
    _new_approve: '新增准入',
    _cancel: '取消输华',
    _estno_change: '厂号变更',
    _mr: '先生',
    _ms: '女士',
    // country
    _argentina: '阿根廷',
    _australia: '澳大利亚',
    _belarus: '白俄罗斯',
    _brazil: '巴西',
    _canada: '加拿大',
    _chile: '智利',
    _china: '中国（港澳台地区除外）',
    _costarica: '哥斯达黎加',
    _france: '法国',
    _hungary: '匈牙利',
    _ireland: '爱尔兰',
    _kazakhstan: '哈萨克斯坦',
    _denmark: '丹麦',
    _germany: '德国',
    _finland: '芬兰',
    _portugal: '葡萄牙',
    _spain: '西班牙',
    _unitedkingdom: '英国',
    _austria: '奥地利',
    _belgium: '比利时',
    _poland: '波兰',
    _romania: '罗马尼亚',
    _latvia: '拉脱维亚',
    // '_mexico': '墨西哥',
    _mongolia: '蒙古国',
    _namibia: '纳米比亚',
    _netherlands: '荷兰',
    _newzealand: '新西兰',
    // '_panama': '巴拿马',
    _serbia: '塞尔维亚',
    _southafrica: '南非',
    _unitedstates: '美国',
    _uruguay: '乌拉圭',
    _bolivia: '玻利维亚',
    _ukraine: '乌克兰',
    _lithuania: '立陶宛',
    _italy: '意大利',
    _switzerland: '瑞士',
    _russia: '俄罗斯',
    // Province
    // Argentina
    _buenosaires: '布宜诺斯艾利斯',
    _catamarca: '卡塔马卡省',
    _chaco: '查科省',
    _chubut: '丘布特省',
    _córdoba: '科尔多瓦',
    _corrientes: '科连特斯省',
    _entreríos: '恩特雷里奥斯省',
    _formosa: '福尔摩沙',
    _jujuy: '胡胡伊省',
    _lapampa: '拉潘帕省',
    _larioja: '拉里奥哈省',
    _mendoza: '门多萨省',
    _misiones: '米西奥斯内斯省',
    _neuquen: '内乌肯省',
    _ríonegrob: '里奥内格罗省',
    _salta: '萨尔塔省',
    _sanjuan: '圣胡安省',
    _sanluis: '圣路易斯省',
    _santacruz: '圣克鲁斯省',
    _santafe: '圣菲省',
    _santiagodelestero: '圣地亚哥-德尔埃斯特罗省',
    '_tierradelfuego,antártidaeislasdelatlánticosurc': '火地岛省',
    _tucumán: '图库曼省',
    // Australia
    _australiancapitalterritory: '澳大利亚首都领地',
    _newsouthwales: '新南威尔士州',
    _northernterritory: '北领地',
    _queensland: '昆士兰州',
    _southaustralia: '南澳大利亚州',
    _tasmania: '塔斯马尼亚州',
    _victoria: '维多利亚州',
    _westernaustralia: '西澳大利亚州',
    // Belarus
    '_brestskaya(brest)': '布列斯特州',
    '_homyelskaya(homyel)': '戈梅利州',
    _horadminsk: '明斯克市',
    '_hrodzyenskaya(hrodna)': '格罗德诺州',
    '_mahilyowskaya(mahilyow)': '莫吉廖夫州',
    _minskaya: '明斯克州',
    '_vitsyebskaya(vitsyebsk)': '维捷布斯克州',
    // Brazil
    _acre: '阿克里州',
    _alagoas: '阿拉戈阿斯',
    _amapa: '阿马帕州',
    _amazonas: '亚马逊州',
    _bahia: '巴伊亚州',
    _ceara: '赛阿拉州',
    _distritofederal: '联邦区',
    _espiritosanto: '圣埃斯皮里图州',
    _goias: '戈亚斯州',
    _maranhao: '马拉尼昂州',
    _matogrosso: '马托格罗索州',
    _matogrossodosul: '南马托格罗索州',
    _minasgerais: '米纳斯吉拉斯州',
    _para: '帕拉州',
    _paraiba: '帕拉伊巴州',
    _parana: '巴拉那州',
    _pernambuco: '伯南布哥州',
    _piaui: '皮奥伊州',
    _riodejaneiro: '里约热内卢州',
    _riograndedonorte: '北里奥格兰德州',
    _riograndedosul: '南里奥格兰德州',
    _rondonia: '朗多尼亚州',
    _roraima: '罗赖马州',
    _santacatarina: '圣卡塔琳娜州',
    _saopaulo: '圣保罗州',
    _sergipe: '塞尔希培州',
    _tocantins: '托坎廷斯州',
    // Canada
    _alberta: '阿尔伯塔',
    _britishcolumbia: '不列颠哥伦比亚',
    _manitoba: '曼尼托巴',
    _newbrunswick: '新不伦瑞克',
    _newfoundland: '纽芬兰',
    _northwestterritories: '西北地区',
    _novascotia: '新斯科舍省',
    _nunavut: '努纳武特',
    _ontario: '安大略省',
    _princeedwardisland: '爱德华王子岛',
    _quebec: '魁北克城',
    _saskatchewan: '萨斯喀彻温',
    _yukonterritory: '育空地区',
    // Chile
    _aisendelgeneralcarlosibanezdelcampo: '伊瓦涅斯将军的艾森大区',
    _antofagasta: '安托法加斯塔大区',
    _araucania: '阿劳卡尼亚大区',
    _atacama: '阿塔卡马大区',
    _aricayparinacota: '阿里卡和帕里纳科塔大区',
    '_bio-bio': '比奥比奥大区',
    _coquimbo: '科金博大区',
    _libertadorgeneralbernardoohiggins: '奥伊金斯将军解放者大区',
    _loslagos: '湖大区',
    _losrios: '河大区',
    _magallanesydelaantarticachilena: '麦哲伦-智利南极大区',
    _maule: '马乌莱大区',
    '_regionmetropolitana(santiago)': '圣地亚哥首都大区',
    _tarapaca: '塔拉帕卡大区',
    _valparaiso: '瓦尔帕莱索大区',
    // China
    _anhui: '安徽',
    _beijing: '北京',
    _chongqing: '重庆',
    _fujian: '福建',
    _gansu: '甘肃',
    _guangdong: '广东',
    _guangxi: '广西',
    _guizhou: '贵州',
    _hainan: '海南',
    _hebei: '河北',
    _heilongjiang: '黑龙江',
    _henan: '河南',
    _hubei: '湖北',
    _hunan: '湖南',
    _jiangsu: '江苏',
    _jiangxi: '江西',
    _jilin: '吉林',
    _liaoning: '辽宁',
    _neimongol: '内蒙古',
    _ningxia: '宁夏',
    _qinghai: '青海',
    _shaanxi: '陕西',
    _shandong: '山东',
    _shanghai: '上海',
    _shanxi: '山西',
    _sichuan: '四川',
    _tianjin: '天津',
    _xinjiang: '新疆',
    '_xizang(tibet)': '西藏',
    _yunnan: '云南',
    _zhejiang: '浙江',
    // Costarica
    _alajuela: '阿拉胡埃拉',
    _cartago: '卡塔戈',
    _guanacaste: '瓜纳卡斯特',
    _heredia: '埃雷迪亚',
    _limon: '利蒙',
    _puntarenas: '彭塔雷纳斯',
    // _sanjose: '圣何塞',
    _sanjose: '圣何塞',
    // France
    _alsace: '阿尔萨斯',
    _aquitaine: '阿基坦',
    _auvergne: '奥弗涅',
    '_basse-normandie': '下诺曼底',
    _bourgogne: '勃艮第',
    _bretagne: '布列塔尼',
    _centre: '中部',
    '_champagne-ardenne': '香槟-阿登',
    _corse: '科西嘉',
    '_franche-comte': '弗朗什-孔泰',
    '_haute-normandie': '上诺曼底',
    '_ile-de-france': '法兰西岛',
    '_languedoc-roussillon': '朗格多克--鲁西雍',
    _limousin: '利穆赞',
    _lorraine: '洛林',
    '_midi-pyrenees': '比利牛斯',
    '_nord-pas-de-calais': '北部-加莱海峡',
    _paysdelaloire: '卢瓦尔',
    _picardie: '皮卡第',
    '_poitou-charentes': '普瓦图-夏朗德',
    '_provence-alpes-cotedazur': '普罗旺斯-阿尔卑斯-蓝色海岸',
    '_rhone-alpes': '罗讷-阿尔卑斯',
    // Hungary
    _budapest: '布达佩斯',
    '_bacs-kiskun': '巴奇-基什孔州',
    _baranya: '巴兰尼亚',
    _bekes: '贝凯什州',
    '_borsod-abauj-zemplen': '包尔绍德-奥包乌伊-曾普伦州',
    _csongrad: '琼格拉德',
    _fejer: '费耶尔',
    '_gyr-moson-sopron': '杰尔-莫松-肖普朗州',
    '_hajdu-bihar': '豪伊杜-比豪尔州',
    _heves: '赫维什州',
    '_jasz-nagykun-szolnok': '加兹-纳杰孔-索尔诺克州',
    _komarom: '科马罗姆州',
    _nograd: '诺格拉德州',
    _pest: '佩斯州',
    _somogy: '绍莫吉州',
    '_szabolc-szatmar-bereg': '索博尔奇-索特马尔-贝拉格州',
    _tolna: '托尔瑙州',
    _vas: '沃什州',
    _veszprem: '维斯普雷姆州',
    _zala: '佐洛',
    // Ireland
    _carlow: '卡尔洛',
    _cavan: '卡范',
    _clare: '克莱尔',
    _cork: '科克',
    _donegal: '多内加尔',
    _dublin: '都柏林',
    _galway: '戈尔韦',
    _kerry: '凯里',
    _kildare: '基尔代尔',
    _kilkenny: '基尔肯尼',
    _laois: '累伊斯',
    _leitrim: '利屈姆',
    _limerick: '利默里克',
    _longford: '朗福德',
    _louth: '劳思',
    _mayo: '梅奥',
    _meath: '米思',
    _monaghan: '莫内根',
    _offaly: '奥法莱',
    _roscommon: '罗斯科门',
    _sligo: '斯来果',
    _tipperary: '提珀雷里',
    _waterford: '瓦特福德',
    _westmeath: '西米思',
    _wexford: '韦克斯福德',
    _wicklow: '威克洛',
    // Kazakhstan
    _almaty: '阿拉木图州',
    _aqmola: '阿克莫拉州',
    _aqtobe: '阿克托别州',
    _astana: '阿斯塔纳',
    _atyrau: '阿特劳州',
    _batysqazaqstan: '西哈萨克斯坦州',
    _bayqongyr: '拜科努尔',
    _mangghystau: '曼格斯套州',
    _ongtustikqazaqstan: '南哈萨克斯坦斯坦州',
    _pavlodar: '巴甫洛达尔',
    _qaraghandy: '加拉干达',
    _qostanay: '库斯塔奈',
    _qyzylorda: '克孜勒奥尔达',
    _shyghysqazaqstan: '东哈萨克斯坦州',
    _soltustikqazaqstan: '北哈萨克斯坦州',
    _zhambyl: '江布尔州',
    // Mexico
    _aguascalientes: '阿瓜斯卡连特斯',
    _bajacalifornia: '下加利福尼亚',
    _bajacaliforniasur: '南下加利福尼亚',
    _campeche: '坎佩切州',
    _chiapas: '恰帕斯州',
    _chihuahua: '奇瓦瓦州',
    _coahuiladezaragoza: '科阿韦拉州',
    _colima: '科利马州',
    _distritourango: '杜兰戈州',
    _guanajuato: '瓜纳华托州',
    _guerrero: '格雷罗州',
    _hidalgo: '伊达尔戈州',
    _jalisco: '哈利斯科州',
    _mexico: '墨西哥',
    _michoacandeocampo: '米却肯州',
    _morelos: '莫雷洛斯州',
    _nayarit: '纳亚里特州',
    _nuevoleon: '新莱昂州',
    _oaxaca: '瓦哈卡州',
    _puebla: '普埃布拉州',
    _queretarodearteaga: '克雷塔罗州',
    _quintanaroo: '金塔纳罗奥州',
    _sansi: '圣路易斯波托西州',
    _sinaloa: '锡那罗亚州',
    _sonora: '索诺拉州',
    _tabasco: '塔巴斯科州',
    _tamaulipas: '塔毛利帕斯州',
    _tlaxcala: '特拉斯卡拉州',
    '_veracruz-llave': '韦拉克鲁斯州',
    _yucatan: '尤卡坦州',
    _zacatecas: '萨卡特卡斯州',
    // Mongolia
    _arhangay: '后杭爱省',
    '_bayan-olgiy': '巴彦乌勒盖省',
    _bayanhongor: '巴彦洪格尔省',
    _bulgan: '布尔干省',
    _darhan: '达尔汗乌勒省',
    _dornod: '东方省',
    _dornogovi: '东戈壁省',
    _dundgovi: '中戈壁省',
    _dzavhan: '扎布汗省',
    _erdenet: '中央省',
    '_govi-tiy': '戈壁阿尔泰省',
    _govisumber: '戈壁苏木贝尔省',
    _hovd: '科布多省',
    _hovsgol: '库苏古尔省',
    _omnogovi: '南戈壁省',
    _orkhon: '鄂尔浑省',
    _ovorhangay: '前杭爱省',
    _selenge: '色楞格省',
    _suhbaatar: '苏赫巴托尔省',
    _tov: '肯特省',
    _ulaanbaatar: '乌兰巴托',
    _uvs: '乌布苏省',
    // Namibia
    _caprivi: '卡普里维区',
    _erongo: '埃龙戈区',
    _hardap: '哈达普区',
    _karas: '卡拉斯区',
    _khomas: '霍马斯区',
    _kunene: '库内内区',
    _ohangwena: '奥汉圭纳区',
    _okavango: '奥卡万戈区',
    _omaheke: '奥马海凯区',
    _omusati: '奥穆萨蒂区',
    _oshana: '奥沙纳区',
    _oshikoto: '奥希科托区',
    _otjozondjupa: '奥乔宗朱帕区',
    // Netherlands
    _drenthe: '德伦特省',
    _flevoland: '弗莱沃兰省',
    _friesland: '弗里斯兰省',
    _gelderland: '赫尔德兰省',
    _groningen: '格罗宁根省',
    _limburg: '林堡省',
    '_noord-brabant': '北布拉邦省',
    '_noord-holland': '北荷兰省',
    _overijssel: '上艾索尔省',
    _utrecht: '乌特勒支省',
    _zeeland: '泽兰省',
    '_zuid-holland': '南荷兰省',
    // Newzealand
    _wellington: '惠灵顿',
    _northland: '北地',
    _auckland: '奥克兰',
    _waikato: '怀卡托',
    _bayofplenty: '普伦蒂湾',
    _gisborne: '吉斯伯尔尼',
    "_hawke'sbay": '霍克湾',
    _taranaki: '塔拉那基',
    '_manawatu-wanganui': '马纳瓦图－旺加努伊',
    _tasman: '塔斯曼',
    _nelson: '纳尔逊',
    _marlborough: '马尔伯勒',
    _westcoast: '西岸',
    _canterbury: '坎特伯雷',
    _otago: '奥塔哥',
    _southland: '南地',
    _chathamIslands: '查塔姆群岛',
    // Panama
    _bocasdeltoro: '博卡斯德尔托罗省',
    _chiriqui: '奇里基省',
    _cocle: '科克莱省',
    _colon: '科隆省',
    _darien: '达连省',
    _herrera: '埃雷拉省',
    _lossantos: '洛斯桑托斯省',
    _panama: '巴拿马',
    _sanblas: '圣布拉斯',
    _veraguas: '贝拉瓜斯省',
    // Serbia
    _north_Backa: '北巴奇卡州',
    _north_banat: '北巴纳特',
    _west_Backa: '西巴奇卡州',
    _central_banat: '贝拉瓜斯省',
    _srem: '斯雷姆州',
    _south_banat: '南巴纳特州',
    _macva: '马切万州',
    _city_of_belgrade: '贝尔格莱德',
    _podunavlje: '波杜那瓦州',
    _branicevo: '布兰尼切夫州',
    _kolubara: '科卢巴拉州',
    _sumadija: '舒马迪亚州',
    _bor: '博尔州',
    _moravica: '莫拉瓦州',
    _zlatibor: '兹拉蒂博尔州',
    _raska: '拉什卡州',
    _pomoravlje: '波莫拉夫列州',
    _zajecar: '札那查尔州',
    _rasina: '拉辛那州',
    _nisava: '尼沙瓦州',
    _toplica: '托普利卡州',
    _pirot: '皮罗特州',
    _kosovska_mitrovica: '科索夫斯卡-米特罗维察',
    _jablanica: '雅布尼亚卡州',
    _pec: '佩奇州',
    _kosovo: '科索沃',
    _kosovo_pomoravlje: '科索沃梅托西亚州',
    _pcinja: '普奇尼亚',
    _prizren: '普里兹伦',
    _south_Backa: '南巴奇卡州',
    // Southafrica
    _easterncape: '东开普省',
    _freestate: '自由州省',
    _gauteng: '豪登省',
    '_kwazulu-natal': '夸祖鲁省/纳塔尔省',
    _mpumalanga: '姆普马兰加省',
    '_north-west': '西北省',
    _northerncape: '北开普省',
    _northernprovince: '林波波省',
    _westerncape: '西开普省',
    // Unitedstates
    _alabama: '亚拉巴马州',
    _alaska: '阿拉斯加州',
    _arizona: '亚利桑那州',
    _arkansas: '阿肯色州',
    _california: '加利福尼亚州',
    _colorado: '西开普省',
    _connecticut: '康涅狄格州',
    _delaware: '特拉华州',
    _districtofcolumbia: '华盛顿哥伦比亚特区',
    _florida: '佛罗里达州',
    _georgia: '格鲁吉亚州',
    _hawaii: '夏威夷州',
    _idaho: '爱达荷州',
    _illinois: '伊利诺伊州',
    _indiana: '印第安纳州',
    _iowa: '爱荷华州',
    _kansas: '堪萨斯州',
    _kentucky: '肯塔基州',
    _louisiana: '路易斯安那州',
    _maine: '缅因州',
    _maryland: '马里兰州',
    _massachusetts: '马萨诸塞州',
    _michigan: '密歇根州',
    _minnesota: '明尼苏达州',
    _mississippi: '密西西比州',
    _missouri: '密苏里州',
    _montana: '蒙大拿州',
    _nebraska: '内布拉斯加州',
    _nevada: '内华达州',
    _newhampshire: '新罕布什尔州',
    _newjersey: '新泽西州',
    _newmexico: '新墨西哥州',
    _newyork: '纽约州',
    _northcarolina: '北卡罗来纳州',
    _northdakota: '北达科他州',
    _ohio: '俄亥俄州',
    _oklahoma: '俄克拉何马州',
    _oregon: '俄勒冈州',
    _pennsylvania: '宾夕法尼亚州',
    _rhodeisland: '罗得岛州',
    _southcarolina: '南卡罗来纳州',
    _southdakota: '南达科他州',
    _tennessee: '田纳西州',
    _texas: '得克萨斯',
    _utah: '犹他州',
    _vermont: '佛蒙特州',
    _virginia: '弗吉尼亚州',
    _washington: '华盛顿州',
    _westvirginia: '西弗吉尼亚州',
    _wisconsin: '威斯康星州',
    _wyoming: '怀俄明州',
    // Uruguay
    _artigas: '阿蒂加斯',
    _canelones: '卡内洛内斯',
    _cerrolargo: '塞罗拉尔戈',
    _colonia: '科洛尼亚',
    _durazno: '杜拉斯诺',
    _flores: '弗洛雷斯',
    _lavalleja: '拉瓦耶哈',
    _maldonado: '马尔多纳多',
    _montevideo: '蒙得维的亚',
    _paysandú: '派桑杜',
    _ríonegro: '内格罗河',
    _rivera: '里韦拉',
    _rocha: '罗恰',
    _salto: '萨尔托',
    _soriano: '索里亚诺',
    _tacuaremb: '塔夸伦博',
    _treinta: '三十三人',
    // Bolivia
    _beni: '贝尼省',
    _chuquisaca: '丘基萨卡省',
    _cochabamba: '科恰班巴省',
    _lapaz: '拉巴斯省',
    _oruro: '奥鲁罗省',
    _pando: '潘多省',
    _potosí: '波托西省',
    _tarija: '塔里哈省',
    // Ukraine
    _cherkasy: '切尔卡瑟州',
    _chernihiv: '切尔尼戈夫州',
    _chernivtsi: '切尔诺夫策州',
    _dnipropetrovsk: '第聂伯罗彼得罗夫斯克州',
    _donetsk: '顿涅茨克州',
    '_ivano-Frankivsk': '伊万诺-弗兰科夫斯克州',
    _kharkiv: '哈尔科夫州',
    _kherson: '赫尔松州',
    _khmelnytskyi: '赫梅利尼茨基州',
    _kiev: '基辅州',
    _kirovohrad: '基洛夫格勒州',
    _luhansk: '卢甘斯克州',
    _lviv: '利沃夫州',
    _mykolaiv: '尼古拉耶夫州',
    _odessa: '敖德萨州',
    _poltava: '波尔塔瓦州',
    _rivne: '罗夫诺州',
    _sumy: '苏梅州',
    _ternopil: '捷尔诺波尔州',
    _vinnytsia: '文尼察州',
    _volyn: '沃伦州',
    _zakarpattia: '外喀尔巴阡州',
    _zaporizhia: '扎波罗热州',
    _zhytomyr: '日托米尔州',
    // Lithuania
    _alytus: '阿雷图斯县',
    _kaunas: '考纳斯县',
    _klaipėda: '克莱佩达县',
    _marijampolė: '马里扬泊列县',
    _panevėžys: '帕涅韦日斯县',
    _šiauliai: '希奥利艾县',
    _tauragė: '陶拉盖县',
    _telšiai: '泰尔希艾县',
    _utena: '乌泰纳县',
    _vilnius: '维尔纽斯县',
    // 英格兰
    _northumberland: '诺森伯兰',
    _tyneandwear: '泰恩-威尔',
    _durham: '达勒姆郡',
    _cumbria: '坎布里亚',
    _lancashire: '兰开夏郡',
    _blackpool: '布莱克浦',
    _blackburnwithdarwen: '布莱克本-达文',
    _westyorkshire: '西约克郡',
    _northyorkshire: '北约克郡',
    _darlington: '达灵顿',
    _stocktononTees: '蒂斯河畔斯多克东',
    _middlesbrough: '米德尔斯堡',
    _hartlepool: '哈特尔浦',
    _redcarandcleveland: '里德卡-克里夫兰 ',
    _york: '约克',
    _eastridingofyorkshire: '约克夏东瑞丁',
    _hull: '赫尔',
    _northlincolnshire: '北林肯郡',
    _northeastlincolnshire: '东北林肯郡',
    _lincolnshire: '林肯郡',
    _nottinghamshire: '诺丁罕郡',
    _nottingham: '诺丁罕',
    _southyorkshire: '南约克郡',
    _derbyshire: '德比郡',
    _derby: '德比',
    _greatermanchester: '大曼彻斯特',
    _merseyside: '默西赛德',
    _halton: '哈尔顿',
    _warrington: '沃灵顿',
    _cheshire: '柴郡',
    _shropshire: '什罗普郡',
    _telfordandwrekin: '特尔福德-里金 ',
    _staffordshire: '斯塔福德郡',
    _stokeonTrent: '特伦特河畔斯多克',
    _westmidlands: '西密德兰郡',
    _warwickshire: '瓦立克郡',
    _leicestershire: '莱斯特郡',
    _leicester: '莱斯特',
    _rutland: '罗特兰',
    _northamptonshire: '北安普敦郡',
    _peterborough: '彼得堡',
    _cambridgeshire: '剑桥郡',
    _norfolk: '诺福克',
    _suffolk: '萨福克',
    _essex: '埃塞克斯',
    _southendonSea: '滨海绍森德',
    _thurrock: '瑟罗克',
    _hertfordshire: '赫特福德郡',
    _bedfordshire: '贝德福郡',
    _luton: '卢顿',
    _miltonkeynes: '米尔顿凯恩斯',
    _buckinghamshire: '白金汉郡',
    _oxfordshire: '牛津郡',
    _gloucestershire: '格洛斯特郡',
    _worcestershire: '伍斯特郡',
    _herefordshire: '赫里福德郡',
    _southgloucestershire: '南格洛斯特郡',
    _bristol: '布里斯托',
    _northsomerset: '北索美塞特',
    _bathandnortheastsomerset: '巴兹和东北索美塞得',
    _wiltshire: '威尔特郡',
    _swindon: '斯温顿',
    _berkshire: '伯克郡',
    _greaterlondon: '大伦敦',
    _medway: '梅德韦',
    _kent: '肯特',
    _eastsussex: '东萨塞克斯',
    _brightonandhove: '布莱顿-荷甫',
    _westsussex: '西萨塞克斯',
    _surrey: '萨里',
    _hampshire: '汉普郡',
    _southampton: '南安普敦',
    _portsmouth: '普兹茅斯',
    _isleofwight: '威特岛',
    _dorset: '多塞特',
    _poole: '普尔',
    _bournemouth: '伯恩茅斯',
    _somerset: '索美塞特',
    _devon: '德文郡',
    _torbay: '托贝',
    _plymouth: '普利茅斯',
    _cornwall: '康沃尔',
    // 苏格兰
    _dumfriesandgalloway: '邓弗里斯-加洛韦',
    _scottishborders: '苏格兰边疆',
    _midlothian: '中洛锡安',
    _eastlothian: '东洛锡安',
    _edinburgh: '爱丁堡',
    _westlothian: '西洛锡安',
    _southlanarkshire: '拉纳克夏南',
    _eastayrshire: '艾尔夏东',
    _southayrshire: '艾尔夏南',
    _northayrshire: '艾尔夏北',
    _inverclyde: '因弗克莱德',
    _renfrewshire: '伦弗鲁夏',
    _eastrenfrewshire: '伦弗鲁夏东',
    _glasgow: '格拉斯哥',
    _northlanarkshire: '拉纳克夏北',
    _falkirk: '福尔柯克',
    _eastdunbartonshire: '邓巴顿夏东',
    _westdunbartonshire: '邓巴顿夏西',
    _argyllandbute: '阿盖尔-比特',
    _stirling: '斯特灵',
    _clackmannanshire: '克拉克曼南夏',
    _fife: '法夫',
    _dundee: '邓迪',
    _angus: '安格斯',
    _perthandkinross: '珀斯-金罗斯',
    _aberdeenshire: '阿伯丁夏',
    _aberdeen: '阿伯丁',
    _moray: '莫里',
    _highland: '高地',
    _eileansiar: '埃利安锡尔',
    _orkney: '奥克尼',
    _shetland: '设得兰',
    // 威尔士
    _merthyrtydfil: '梅瑟蒂德菲尔',
    _caerphilly: '卡菲利',
    _blaenaugwent: '布莱诺格温特',
    _torfaen: '托尔芬',
    _monmouthshire: '蒙茅斯郡',
    _newport: '纽波特',
    _cardiff: '加的夫',
    _valeofglamorgan: '格拉摩根谷',
    _bridgend: '布里真德',
    _rhonddacynontaff: '朗达卡农塔夫',
    _neathporttalbot: '下塔尔波特港',
    _swansea: '斯旺西',
    _carmarthenshire: '卡马森郡',
    _ceredigion: '锡尔迪金',
    _powys: '波厄斯',
    _wrexham: '域斯咸',
    _flintshire: '弗林特郡',
    _denbighshire: '登比郡',
    _conwy: '康威',
    _gwynedd: '格温内斯',
    _isleofanglesey: '安格尔西岛',
    _pembrokeshire: '彭布鲁克郡',
    // 北爱尔兰
    _alds: '阿兹',
    _kastleleagh: '卡斯尔雷',
    _down: '唐',
    _northdown: '北唐',
    _banbridge: '班布里奇',
    _belfast: '贝尔法斯特',
    _lisburn: '利斯本',
    _antrim: '安特里姆',
    _ballymena: '巴利米纳',
    _ballymoney: '巴利马尼',
    _carrifergus: '卡里克弗格斯',
    _larne: '拉恩',
    _moyle: '莫伊尔',
    _newtownabbey: '纽敦阿比',
    _armagh: '阿马',
    _klaigavon: '克雷加文',
    _newlyandmoulne: '纽里-莫恩',
    _coleraine: '科尔雷恩',
    _maghelafelt: '马拉费尔特',
    _derry: '德里',
    _limavady: '利马瓦迪',
    _cookstown: '库克斯敦',
    _dungannon: '丹甘嫩',
    _omagh: '奥马',
    _stlabane: '斯特拉班',
    _fermanagh: '弗马纳',
    // 俄罗斯
    _adygea: '阿迪格共和国',
    _altai: '阿尔泰共和国',
    _altaiKrais: '阿尔泰边疆区',
    _amur: '阿穆尔州',
    _arkhangelsk: '阿尔汉格尔斯克州',
    _astrakhan: '阿斯特拉罕州',
    _bashkortostan: '巴什科尔托斯坦共和国',
    _belgorod: '别尔哥罗德州',
    _bryansk: '布良斯克州',
    _buryatia: '布里亚特共和国',
    _chechnya: '车臣共和国',
    _chelyabinsk: '车里雅宾斯克州',
    _chukotka: '楚科奇自治区',
    _chuvashia: '楚瓦什共和国',
    _crimea: '克里米亚共和国',
    _dagestan: '达吉斯坦共和国',
    _ingushetia: '印古什共和国',
    _irkutsk: '伊尔库茨克州',
    _ivanovo: '伊万诺沃州',
    _jewish: '犹太自治州',
    _kabardinoBalkaria: '卡巴尔达-巴尔卡尔共和国',
    _kaliningrad: '加里宁格勒州',
    _kalmykia: '卡尔梅克共和国',
    _kaluga: '卡卢加州',
    _kamchatka: '堪察加边疆区',
    _karachayCherkessia: '卡拉恰伊-切尔克斯共和国',
    _karelia: '卡累利阿共和国',
    _kemerovo: '科麦罗沃州',
    _khabarovsk: '哈巴罗夫斯克边疆区',
    _khakassia: '哈卡斯共和国',
    _khantyMansi: '汉特-曼西自治区',
    _kirov: '基洛夫州',
    _komi: '科米共和国',
    _kostroma: '科斯特罗马州',
    _krasnodar: '克拉斯诺达尔边疆区',
    _krasnoyarsk: '克拉斯诺亚尔斯克边疆区',
    _kurgan: '库尔干州',
    _kursk: '库尔斯克州',
    _leningrad: '列宁格勒州',
    _lipetsk: '利佩茨克州',
    _magadan: '马加丹州',
    _mariEl: '马里埃尔共和国',
    _mordovia: '莫尔多瓦共和国',
    _moscow: '莫斯科',
    _moscowOblasts: '莫斯科州',
    _murmansk: '摩尔曼斯克州',
    _nenets: '涅涅茨自治区',
    _nizhnyNovgorod: '下诺夫哥罗德州',
    _northOssetiaAlania: '北奥塞梯-阿兰共和国',
    _novgorod: '诺夫哥罗德州',
    _novosibirsk: '新西伯利亚州',
    _omsk: '鄂木斯克州',
    _orenburg: '奥伦堡州',
    _oryol: '奥廖尔州',
    _penza: '奔萨州',
    _perm: '彼尔姆边疆区',
    _primorsky: '滨海边疆区',
    _pskov: '普斯科夫州',
    _rostov: '罗斯托夫州',
    _ryazan: '梁赞州',
    _sakhalin: '萨哈林州',
    _samara: '萨马拉州',
    _saratov: '萨拉托夫州',
    _sevastopol: '塞瓦斯托波尔',
    _smolensk: '斯摩棱斯克州',
    _stPetersburg: '圣彼得堡',
    _stavropol: '斯塔夫罗波尔边疆区',
    _sverdlovsk: '斯维尔德洛夫斯克州',
    _tambov: '坦波夫州',
    _tatarstan: '鞑靼斯坦共和国',
    _tomsk: '托木斯克州',
    _tula: '图拉州',
    _tuva: '图瓦共和国',
    _tver: '特维尔州',
    _tyumen: '秋明州',
    _udmurtia: '乌德穆尔特共和国',
    _ulyanovsk: '乌里扬诺夫斯克州',
    _vladimir: '弗拉基米尔州',
    _volgograd: '伏尔加格勒州',
    _vologda: '沃洛格达州',
    _voronezh: '沃罗涅日州',
    _yakutia: '萨哈（雅库特）共和国',
    _yamaloNenets: '亚马尔-涅涅茨自治区',
    _yaroslavl: '雅罗斯拉夫尔州',
    _zabaykalsky: '外贝加尔边疆区',
    // 奥地利
    _kärnten: '克恩顿州',
    _oberösterreich: '上奧地利州‎',
    _steiermark: '施泰尔马克州',
    // 丹麦
    _esbjerg: '埃斯比约',
    _frederikshavn: '腓特烈港',
    _herning: '海宁',
    _horsens: '霍森斯',
    _ringsted: '灵斯泰兹',
    _rønne: '伦讷',
    _sønderborg: '森讷堡',
    _thisted: '齐斯泰兹',
    _vejen: '瓦伊恩',
    _vejle: '瓦埃勒',
    // 芬兰
    _länsi_suomi: '西芬兰省',
    // 德国
    _baden_württemberg: '巴登-符腾堡州',
    _brandenburg: '勃兰登堡州',
    _freistaat_bayern: '巴伐利亚州',
    _lower_saxony: '下萨克森州',
    _nordrhein_westfalen: '北莱茵-威斯特法伦州',
    _rheinland_pfalz: '莱茵兰-普法尔茨州',
    _sachsen_anhalt: '萨克森-安哈尔特',
    // 意大利
    _co: '科莫省',
    _cr: '克雷莫纳省',
    _mn: '曼托瓦省',
    _mo: '摩德纳省',
    _pr: '帕尔马省',
    // 葡萄牙
    _evora: '埃武拉区',
    _leiria: '莱里亚区',
    _lisboa: '里斯本区',
    _santarém: '圣塔伦区',
    _setúbal: '塞图巴尔区',
    // 西班牙
    _badajoz: '巴达霍斯省',
    _barcelona: '巴塞罗那省',
    _burgos: '布尔戈斯省',
    _cuenca: '昆卡省',
    _girona: '赫罗纳省',
    _huelva: '韦尔瓦省',
    _huesca: '韦斯卡省',
    _las_palmas: '拉斯帕尔马斯省',
    _león: '莱昂省',
    _lérida: '莱里达省',
    _madrid: '马德里自治区',
    _málaga: '马拉加省',
    _murcia: '穆尔西亚省',
    _pontevedra: '蓬特韦德拉省',
    _salamanca: '萨拉曼卡省',
    _toledo: '托莱多省',
    _zaragoza: '萨拉戈萨省',
    // 瑞士
    _basel_stadt: '巴塞尔城市州',
    _bern: '伯尔尼州',
    _solothurn: '索洛图恩州',
    // Common
    termsandconditions: '服务条款',
    privacypolicy: '免责声明',
    login: '登录',
    username: '输入您的用户名',
    password: '输入您的密码',
    captcha: '验证码',
    remeberme: '记住账号',
    forgetpwd: '忘记密码',
    register: '注册',
    // Pagination
    paginationpage: '页码',
    paginationfirst: '首页',
    paginationlast: '尾页',
    paginationprevious: '上一页',
    paginationnext: '下一页',
    // 试用权限提示
    trialtipprefix: '目前您的账号拥有查看',
    trialtipsuffix: '篇必孚铂金会员信息的试用权限。如有疑问，请联系必孚（中国）官方微信：BTC305548723，或致电028-64235857。',
    closebtn: '关闭',
    confirmbtn: '确认',
    // 个人中心
    newpwd: '新密码',
    renewpwd: '确认新密码',
    // 缴费页面
    chargetips: '请确认您的注册信息是否正确，如信息有误，请前往“个人中心”进行修改，以便工作人员与您取得联系。',
    companyname: '公司名称',
    contactperson: '联系人',
    gender: '性别',
    mobile: '手机',
    email: '输入您的邮箱',
    gendermr: '先生',
    genderms: '女士',
    membershipapply: '申请',
    membershiptryapply: '试用申请',
    _vip_member_en: '',
    _vip_member: '必孚会员',
    _gold_member: '必孚VIP会员',
    _platinum_member: '必孚铂金会员',
    _porktochina_member: '必孚破壳',
    _btc_meat_member: '必孚肉集',
    sendrequest: '提交申请',
    cancelrequest: '取消',
    memberpayreqsuccessful: '您已提交会员申请，必孚（中国）工作人员将尽快与您取得联系。如有疑问，请联系官方微信：BTC305548723，或致电028-64235857。',
    membertryreqsuccessful: '您已提交会员试用申请，必孚（中国）工作人员将尽快与您取得联系。如有疑问，请联系官方微信：BTC305548723，或致电028-64235857。',
    // 忘记密码
    resetpwd: '重置密码',
    forgetwdname: '请填写您的用户名',
    forgetwdemail: '请填写您的邮箱',
    nouser: '该用户不存在',
    useremailnotmatch: '用户名与邮箱不匹配',
    mailmsg: '重置密码的链接已发送至您的邮箱，请注意查收。',
    resetsuccessful: '密码重置成功',
    newpwdnotempty: '新密码不能为空。',
    newpwdrule: '新密码由6-20个英文字母或数字组成。',
    repwdnotempty: '请再次输入新密码。',
    repwdnotmatch: '两次输入的密码不一致。',
    pwdrepeat: '新密码与原密码不能相同',
    dateMsg: '起始日期不能晚于截止日期',
    // 注册
    regsuccessful: '<p style="color: #014379; font-size: 18px; font-weight: bold;margin: 20px 0;">您已注册成功！<p/><p style="color: #666666;font-size: 16px;margin-bottom: 50px">现在您已获得更多平台使用权限！感谢您对必孚（中国）的信任和支持！如有任何疑问，请联系：电话028-64235857；微信btchuiyuan 。</p>',
    //
    sendestemail: '鉴于疫情持续发展影响，必孚工厂直通车第三方“邮差服务”暂时停止，感谢您的理解和支持。',
    // 开通会员
    sentmembershipsful: '<p style="color: #014379; font-size: 18px; font-weight: bold;margin: 20px 0;">发送成功<p/><p style="color: #666666;font-size: 16px;margin-bottom: 50px">您已提交成功，为给您带来更好的产品体验及更优质的服务，必孚客服人员将与您取得联系。如有疑问，请联系我们，电话：028-64235857，微信：btchuiyuan。</p>',
    // 开通肉集
    snetmembershiprouji: '<p style="color: #014379; font-size: 18px; font-weight: bold;margin: 20px 0;">申请开通必孚肉集<p/><p style="color: #666666;font-size: 16px;margin-bottom: 50px">您已提交成功，为给您带来更好的产品体验及更优质的服务，必孚客服人员将与您取得联系。如有疑问，请联系我们，电话：028-64235857，微信：btc3rdjiaoyi。</p>',
    // 新闻列表
    largeview: '大视图',
    smallview: '小视图',
    readmore: '详情',
    morenews: '更多',
    country: '国别',
    category: '类别',
    keyword: '关键词',
    // 新闻详情
    publishtime: '发布时间：',
    needlogin: '登录阅读更多内容...',
    copylink: '复制链接',
    copysuccess: '复制成功!',
    copyfailed: '复制失败!',
    // 验证码
    captchatimeout: '验证码已失效',
    captchaerr: '验证码有误',
    error: '系统错误',
    contactemailsendok: '发送成功',
    savedsuccessfully: '保存成功',
    wrongpwd: '密码修改失败，当前密码输入错误。',
    other: '其他',
    _source_of_origin_subtype: '来源国',
    _customs_subtype: '海关',
    _province_subtype: '省份',
    _chinese_importer_subtype: '进口商格局',
    _beef_cuts_subtype: '部位',
    _exporters_subtype: '出口商格局',
    _hongkong_subtype: '香港',
    _india_subtype: '印度',
    _other_subtype: '其他',
    // 登录
    userpwdnotmatch: '用户名或密码错误',
    _othercountry: '其他国家或地区',
    // 行情周报
    _futures: '期货',
    _spotgoods: '现货',
    _livecattle: '活牛',
    _marketanalysis: '市场分析',
    // 行情走势部位代码
    _nn80vl: '牛腩80vl',
    _nbr: '牛脖肉',
    _jbr: '肩部肉',
    _qx: '前胸',
    _hx: '后胸',
    _nl97vl: '牛霖97vl',
    _nq90vl: '牛前90vl',
    _dml97vl: '大米龙97vl',
    _qj: '前腱',
    _hj: '后腱',
    _gj: '龟腱',
    _xl3d5kgj: '西冷3.5kg+',
    _yr2d5kgj: '眼肉2.5kg+',
    _tr97vl: '臀肉97vl',
    _xml97vl: '小米龙97vl',
    _blj: '保乐肩',
    _njr: '嫩肩肉',
    _sr80vl: '碎肉80vl',
    _qcb: '脐橙板',
    _xl3d0kgj: '西冷3.0kg+',
    _sndbr: '上脑带脖肉',
    _qn: '全牛',
    _nq5jt: '牛前5件套',
    _nq7jt: '牛前7件套',
    // 澳新
    _sr80cl: '碎肉80CL',
    _nj: '牛腱',
    _cxdml: '粗修大米龙',
    _snx: '上脑心',
    _nl: '牛霖',
    _bj: '板腱',
    _nfr: '牛腹肉',
    _xl: '西冷',
    _xml: '小米龙',
    _tr: '臀肉',
    _dgsft: '带骨四分体',
    _nqhbwr65cl: '牛前后部位肉65CL',
    _bfbjxdml: '百分百精修大米龙',
    _dgnxp: '带骨牛小排',
    _sjjr: '上肩胛肉',
    _sn: '上脑',
    _bfbjxxml: '百分百精修小米龙',
    _yrx: '眼肉心',
    // 新增
    _nq4jt: '牛前4件套',
    _nq6jt: '牛前6件套',
    _nq8jt: '牛前8件套',
    _jxtr: '精修臀肉',
    _tyrx: '臀腰肉心',
    _tyr: '臀腰肉',
    '_yrx2kg+': '眼肉心2kg+',
    _qnhgt: '全牛（含骨头）',
    _dgxr: '带骨胸肉',
    _qhj: '前后腱',
    _jjbj: '肩胛背肩',
    _sr70vl: '碎肉70vl',
    _dgtr: '带盖臀肉',
    _58l: '5/8肋',
    _dgnn: '带骨牛腩',
    _qn23q: '全牛23切',
    _snjj: '上脑肩胛',
    _trg: '臀肉盖',
    _xr: '胸肉',
    _544l: '5/4/4肋',
    _yrg: '眼肉盖',
    _yr: '眼肉',
    _qn6q: '全牛6切',
    _jjdsn: '肩胛带上脑',
    _jjr: '肩胛肉',
    // 行情走势国家代码
    argentina: '阿根廷',
    brazil: '巴西',
    uruguay: '乌拉圭',
    australia: '澳大利亚',
    newzealand: '新西兰',
    _serious: '真实价格',
    _justajoke: '仅供参考',

    /** 权限相关**/
    // 必孚可视
    _beefeyes: '_beefeyes',
    // 必孚信息
    _beefpicked: '_beefpicked',
    _beefnewsandevents: '_beefnewsandevents',
    _newsanalysis: '_newsanalysis',
    // 必孚简报
    _btcreport: '_btcreport',
    // 必孚数据
    _btcdata: '_btcdata',
    // 必孚行情
    _beefpriceindex: '_beefpriceindex',
    // 必孚行情 - 市场分析    // 必孚数据分析
    _beefmarketanalysis: '_beefmarketanalysis'
  }
}
